// export default CustomSelect;
import React, { forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { Select, MenuItem, InputLabel, styled } from "@mui/material";

export const StyledSelect = styled(Select)(({ theme, ownerState }) => {
  const { palette, functions, typography, borders, boxShadows } = theme;

  const { inputColors, grey, white, transparent, info, text, dark } = palette;
  const { inputBoxShadow } = boxShadows;
  const { pxToRem, boxShadow } = functions;
  const { size: fontSize } = typography;
  const { borderRadius } = borders;

  const { size, error, success, iconDirection, disabled, darkMode, asSelect } = ownerState;
  // styles for the input with size="small"
  const smallStyles = () => ({
    fontSize: fontSize.xs,
    padding: `${pxToRem(6)} ${pxToRem(12)}`,
  });

  // styles for the input with size="large"
  const largeStyles = () => ({
    padding: pxToRem(12),
  });

  // styles for the input containing an icon
  const withIconStyles = () => ({
    borderColor: transparent.main,
    borderRadius: borderRadius.md,
  });
  let borderColor = inputColors.borderColor.main;
  let boxShadowValue = inputBoxShadow;
  let focusedBorderColor = info.main;

  if (error) {
    borderColor = inputColors.error;
    focusedBorderColor = inputColors.error;
    boxShadowValue = `${boxShadow([0, 3], [9, 0], inputColors.error, 0)}, ${boxShadow(
      [3, 4],
      [8, 0],
      inputColors.error,
      0.1
    )}`;
  } else if (success) {
    borderColor = inputColors.success;
    focusedBorderColor = inputColors.success;
    boxShadowValue = `${boxShadow([0, 3], [9, 0], inputColors.success, 0)}, ${boxShadow(
      [3, 4],
      [8, 0],
      inputColors.success,
      0.1
    )}`;
  }

  const errorStyles = () => ({
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd5c70' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23fd5c70' stroke='none'/%3E%3C/svg%3E\")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: `right ${pxToRem(12)} center`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
  });

  // styles for the input with success={true}
  const successStyles = () => ({
    backgroundImage:
      "url(\"data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 10 8'%3E%3Cpath fill='%2366d432' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E\")",
    backgroundRepeat: "no-repeat",
    backgroundPosition: `right ${pxToRem(12)} center`,
    backgroundSize: `${pxToRem(16)} ${pxToRem(16)}`,
  });

  return {
    backgroundColor: disabled ? `${grey[200]} !important` : white.main,
    outline: 0,
    pointerEvents: disabled ? "none" : "auto",
    ...(size === "small" && smallStyles()),
    ...(size === "large" && largeStyles()),
    ...(error && errorStyles()),
    ...(success && successStyles()),
    ...((iconDirection === "left" || iconDirection === "right") && withIconStyles()),
    borderRadius: `${pxToRem(borderRadius)}`, // Adjust as needed
    width: "100%!important",
    backgroundSize: `${pxToRem(20)} ${pxToRem(16)}`,
    paddingRight: `${pxToRem(30)}`,
    padding: `${pxToRem(12)}`, // Adjust as needed
    borderColor: borderColor,
    boxShadow: boxShadowValue,
    color: text.main, // Text color
    "&:hover": {
      borderColor: "#11cdef",
    },
    "&.Mui-focused": {
      outline: 0,
      borderColor: focusedBorderColor,
      boxShadow: boxShadowValue,
    },
    "&.MuiSelect-select": {
      width: "100%!important", // Set your desired background color for the options menu
    },
    "&.MuiSelect-selectMenu": {
      backgroundColor: "white", // Set your desired background color for the options menu
    },

    "&.MuiInputBase-multiline": {
      padding: `${pxToRem(10)} ${pxToRem(12)}`,
    },
  };
});

const CustomSelect = forwardRef(
  ({ value, displayEmpty, options, label, handleChange,name, ...rest }) => {
    return (
      <>
        <StyledSelect
          value={value}
          name={name}
          displayEmpty
          {...rest}
          onChange={(event) => handleChange(event)}
        >
          {displayEmpty && (
            <MenuItem value="">
              <em>{label}</em>
            </MenuItem>
          )}
          {options.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </StyledSelect>
      </>
    );
  }
);

CustomSelect.propTypes = {
  value: PropTypes.string.isRequired,
  displayEmpty: PropTypes.bool.isRequired,
  handleChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export default CustomSelect;
