import * as React from "react";
import { styled } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";

// export const LayoutToolTipRoot = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} />
// ))(({ theme , ownerState}) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     maxWidth: 500,
//   },
//   [`& .${tooltipClasses.tooltip}`]: {
//     // backgroundColor: theme.palette.common.black,
//     // color: "white",
//     boxShadow: theme.shadows[1],
//     fontSize: 12,
//   },
// }));





export default styled(Tooltip)(({ theme, ownerState }) => {
  const { palette, typography, functions } = theme;
  const { color,background, textTransform, verticalAlign, fontWeight, opacity, textGradient, darkMode } =
    ownerState;

  const { gradients, transparent, white } = palette;
  const { fontWeightLight, fontWeightRegular, fontWeightMedium, fontWeightBold } = typography;
  const { linearGradient } = functions;

  // fontWeight styles
  const fontWeights = {
    light: fontWeightLight,
    regular: fontWeightRegular,
    medium: fontWeightMedium,
    bold: fontWeightBold,
  };

  // styles for the typography with textGradient={true}
  const gradientStyles = () => ({
    backgroundImage:
      color !== "inherit" && color !== "text" && color !== "white" && gradients[color]
        ? linearGradient(gradients[color].main, gradients[color].state)
        : linearGradient(gradients.dark.main, gradients.dark.state),
    display: "inline-block",
    WebkitBackgroundClip: "text",
    WebkitTextFillColor: transparent.main,
    position: "relative",
    zIndex: 1,
  });

  // color value
  let colorValue = color === "inherit" || !palette[color] ? "inherit" : palette[color].main;

  if (darkMode && (color === "inherit" || !palette[color])) {
    colorValue = "inherit";
  } else if (darkMode && color === "dark") colorValue = white.main;

  return {
    [`& .${tooltipClasses.tooltip}`]: {
            maxWidth: 500,
          },
          [`& .${tooltipClasses.tooltip}`]: {
            // backgroundColor: theme.palette.common.black,
            // color: "white",
            boxShadow: theme.shadows[1],
            fontSize: 12,
          },
    opacity,
    textTransform,
    verticalAlign,
    textDecoration: "none",
    color: colorValue,
    fontWeight: fontWeights[fontWeight] && fontWeights[fontWeight],
    ...(textGradient && gradientStyles()),
  };
});
