import "./App.css";
import React, { useEffect, useCallback, Suspense } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import Home from "./components/Home";
import Services from "./components/Services";
import Contact from "./components/Contact";
import About from "./components/about";
import LoanCalculator from "./components/LoanCalculator";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import Verify from "./components/auth/Verify";
import ForgotPassword from "./components/auth/ForgotPassword";
import Account from "./components/dashboard/Account";
import ImportLoans from "./components/ImportLoans";
import CheckOffLoans from "./components/CheckOffLoans";
import AutoLogbookLoans from "./components/AutoLogbookLoans";
import PersonalLoans from "./components/PersonalLoans";
import Referral from "./components/Referral";
import TermsAndConditions from "./components/TermsAndCoditions";
// import Overview from "./layouts/profile/Index";
import { LayoutControllerProvider } from "./context";
import Profile from "./layouts";
import PerfectScrollbar from "react-perfect-scrollbar";
import HomeLayout from "./components/homeLayout";
const ProtectedRoute = React.lazy(() => import("./utils/ProtectedRoute"));

function App() {
  const Logout = () => {
    let navigate = useNavigate();
    const out = useCallback(() => {
      localStorage.clear();
      navigate("/");
    }, [navigate]);

    useEffect(() => {
      out();
    }, [out]);
    return null;
  };

  return (
    <div className="App w-100 h-100">
      <BrowserRouter>
        <Suspense fallback={<p> Loading ... </p>}>
          <Routes>
            <Route path={"/loan-calculator"} element={<LoanCalculator />}></Route>
          </Routes>
          <LayoutControllerProvider>
            <PerfectScrollbar>
              <Profile />
            </PerfectScrollbar>
          </LayoutControllerProvider>
          <LayoutControllerProvider>
            <HomeLayout />
          </LayoutControllerProvider>
        </Suspense>
      </BrowserRouter>
    </div>
  );
}

export default App;
