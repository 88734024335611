import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Card, Container, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import ProfileEditCard from "../../../../widgets/Cards/InfoCards/ProfileSettingsCard";

import LayoutStepper from "../../../../components/LayoutStepper";
import PropTypes from "prop-types";
import { createNextOfKin, profileEdit, uploadProfileDocs } from "../../../../store/profile.store";
import { getFromLocalStorage } from "../../../../utils/local-storage";
import { setState } from "../../../../store/users.store";
import { ToastContainer } from "react-toastify";
import SnackbarContent from "../../../../components/Snackbar/SnackbarContent";
import Snackbar from "../../../../components/Snackbar/Snackbar";
import { AddAlert } from "@mui/icons-material";

const PlatformSettings = forwardRef(({ initialIndex, setTabValue }, ref) => {
  const user = useSelector((state) => state?.users?.user);
  const steps = ["Profile", "nextOfKin", "upload"];
  const [userData, setUserData] = useState(user);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: "",
    color: "info", // default color
  });

  const [activeStep, setActiveStep] = useState(initialIndex);
  const [completed, setCompleted] = useState({});
  const dispatch = useDispatch();

  useEffect(() => {
    console.log("PlatformSettings mounted with initialStep:", initialIndex);
    handleStep(initialIndex);
  }, []);

  const showNotification = (message, color = "info") => {
    setSnackbar({ open: true, message, color });
    setTimeout(() => {
      setSnackbar({ ...snackbar, open: false });
    }, 6000);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // onCancel()
    // Add  form submission logic here
    console.log("Form submitted!");
  };

  const handleCancel = () => {
    // Placeholder function for onCancel
  };

  const handleSaveProfile = async (profileData) => {
    console.log("profileData", profileData);

    await dispatch(profileEdit(profileData)).then((result) => {
      if (result?.payload?.success === true) {
        dispatch(setState("user", result?.payload?.data));
        dispatch(setState("status_completion", result?.payload?.status_completion));
        showNotification("Profile updated successfully!", "success");

        setTabValue(0);
      }
    });
  };

  const handleSaveNextOfKin = async (kinData) => {
    // Placeholder function for onSaveNextOfKin
    await dispatch(createNextOfKin(kinData)).then((result) => {
      if (result?.payload?.success === true) {
        dispatch(setState("user", result?.payload?.data));
        dispatch(setState("status_completion", result?.payload?.status_completion));
        setTabValue(0);
        showNotification("Profile updated successfully!", "success");
      }
    });
  };

  const handleSaveDocument = async (documentData) => {
    // Placeholder function for onSaveDocument
    console.log("documents saved", documentData);
    await dispatch(uploadProfileDocs(documentData)).then((result) => {
      if (result?.payload?.success === true) {
        dispatch(setState("user", result?.payload?.data));

        dispatch(setState("status_completion", result?.payload?.status_completion));
        setTabValue(0);
        showNotification("Profile updated successfully!", "success");

      }
    });
  };

  const totalSteps = () => {
    return steps.length;
  };

  const completedSteps = () => {
    return Object.keys(completed).length;
  };

  const isLastStep = () => {
    return activeStep === totalSteps() - 1;
  };

  const allStepsCompleted = () => {
    return completedSteps() === totalSteps();
  };

  const handleNext = () => {
    const newActiveStep =
      isLastStep() && !allStepsCompleted()
        ? // It's the last step, but not all steps have been completed,
          // find the first step that has been completed
          steps.findIndex((step, i) => !(i in completed))
        : activeStep + 1;
    setActiveStep(newActiveStep);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleStep = (step) => () => {
    console.log("completed step", step);
    setActiveStep(step);
  };

  const handleComplete = () => {
    const newCompleted = completed;
    newCompleted[activeStep] = true;
    setCompleted(newCompleted);
    handleNext();
  };

  const handleReset = () => {
    setActiveStep(0);
    setCompleted({});
  };

  return (
    <Card>
      <Container sx={{ mt: 3, mb: 3 }} maxWidth="xl">
        <Grid container spacing={4}>
          {/* Profile card */}
          <Grid item xl={12} xs={12}>
            <LayoutStepper
              steps={steps}
              completed={completed}
              activeStep={activeStep}
              handleStep={(index) => handleStep(index)}
            />

            <ProfileEditCard
              userData={userData}
              onCancel={handleCancel}
              activeStep={activeStep}
              onSaveProfile={handleSaveProfile}
              onSaveNextOfKin={handleSaveNextOfKin}
              onSaveDocuments={handleSaveDocument}
            />
          </Grid>
        </Grid>
      </Container>
      <Snackbar
        place="tc"
        color={snackbar.color}
        icon={AddAlert}
        message={snackbar.message}
        open={snackbar.open}
        closeNotification={() => setSnackbar({ ...snackbar, open: false })}
        close
      />

    
    </Card>
  );
});

PlatformSettings.propTypes = {
  initialIndex: PropTypes.number.isRequired,
  setTabValue: PropTypes.func.isRequired,
};

export default PlatformSettings;
