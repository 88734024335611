

// @mui material components
import Card from "@mui/material/Card";

// Layout Dashboard 2 MUI components
import LayoutBox from "../../components/LayoutBox";
import LayoutTypography from "../../components/LayoutTypography";

// Layout Dashboard 2 MUI examples
import DashboardLayout from "../../widgets/LayoutContainers/DashboardLayout";
import DashboardNavbar from "../../widgets/Navbars/DashboardNavbar";
import Footer from "../../widgets/Footer";
import Table from "../../widgets/Tables/Table";

// Data
import loanTableData, { Function, LoansTaken } from "./data/loansTableData";
import loanTrackingTableData from "./data/loanTrackingTableData";
import { useDispatch, useSelector } from "react-redux";
import logo from "../../assets/images/logo.png"
import LayoutBadge from "../../components/LayoutBadge";
import { useEffect, useState } from "react";
import { myLoans } from "../../store/loan.store";
function Tables() {
  const dispatch = useDispatch();
  const[rows, setRows]=useState([])
  const my_loans = useSelector((state) => state.loans.my_loans);
  const { columns} = loanTableData;
  const { columns: prCols } = loanTrackingTableData


  useEffect(() => {
    // Fetch myloans
    dispatch(myLoans()).then((res)=>{
      // console.log("The results from fetching my loans is ", res)
    })

  }, []); // Run once on component mount


  


  useEffect(()=>{
    const data = my_loans?.map((loan) => {
      // Format startDate
      const startDate = new Date(loan.created_at).toLocaleDateString();
    
      // Format requested_amount
      const requestedAmount = parseFloat(loan.requested_amount).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      });
    
      return {
        LoansTaken: <LoansTaken image={logo} name={loan.user_name} email={loan.user_email} />,
        loanInfo: <Function job={loan.loan_purpose} org={requestedAmount} />,
        status: (
          <LayoutBadge
            variant="gradient"
            badgeContent={loan.status}
            color={loan.status === "New" ? "success" : "secondary"}
            size="xs"
            container
          />
        ),
        startDate: (
          <LayoutTypography variant="caption" color="secondary" fontWeight="medium">
            {startDate}
          </LayoutTypography>
        ),
        action: (
          <LayoutTypography
            component="a"
            href="#"
            variant="caption"
            color="secondary"
            fontWeight="medium"
          >
            View
          </LayoutTypography>
        ),
      };
    });

    setRows(data)

  },[])
  




  return (
    <DashboardLayout>
      <DashboardNavbar />
      <LayoutBox py={3}>
        <LayoutBox mb={3}>
          <Card>
            <LayoutBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
              <LayoutTypography variant="h6">Loans table</LayoutTypography>
            </LayoutBox>
            <LayoutBox
              sx={{
                "& .MuiTableRow-root:not(:last-child)": {
                  "& td": {
                    borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                      `${borderWidth[1]} solid ${borderColor}`,
                  },
                },
              }}
            >
              <Table columns={columns} rows={rows} />
            </LayoutBox>
          </Card>
        </LayoutBox>
        <Card>
          <LayoutBox display="flex" justifyContent="space-between" alignItems="center" p={3}>
            <LayoutTypography variant="h6">Loan Tracking</LayoutTypography>
          </LayoutBox>
          <LayoutBox
            sx={{
              "& .MuiTableRow-root:not(:last-child)": {
                "& td": {
                  borderBottom: ({ borders: { borderWidth, borderColor } }) =>
                    `${borderWidth[1]} solid ${borderColor}`,
                },
              },
            }}
          >
            <Table columns={prCols} rows={[]} />
          </LayoutBox>
        </Card>
      </LayoutBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Tables;
