import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import LayoutInput from "../../../../components/LayoutInput";
import { FormControl } from "react-bootstrap";
import { InputLabel, Select, Switch } from "@mui/material";
import LayoutBox from "../../../../components/LayoutBox";
import LayoutTypography from "../../../../components/LayoutTypography";
import LayoutButton from "../../../../components/LayoutButton";
import { useDispatch, useSelector } from "react-redux";
import { getBankBranches, getBankDetails } from "../../../../store/banks.store";

function ProfileEditCard({
  userData,
  onCancel,
  activeStep,
  onSaveProfile,
  onSaveNextOfKin,
  onSaveDocuments,
}) {
  const dispatch = useDispatch();
  const banks = useSelector((state) => state.banks.banks);
  const branches = useSelector((state) => state.banks.bank_branches);
  const [branchOptions, setBranchOptions] = useState([]);
  const [distribution_choice, setDistributionChoice] = useState(
    userData?.customer_profile?.distribution_choice ?? "M-Pesa"
  );
  const [kinData, setKinData] = useState(
    userData?.next_of_kins?.map((kin) => ({
      kinName: kin.name || "",
      kinRelationship: kin.relationship || "",
      kinPhone: kin.phone_no || "",
      kinEmail: kin.email || "",
    })) || [
      {
        kinName: "",
        kinRelationship: "",
        kinPhone: "",
        kinEmail: "",
      },
      {
        kinName: "",
        kinRelationship: "",
        kinPhone: "",
        kinEmail: "",
      },
    ]
  );

  const [formData, setFormData] = useState({
    first_name: userData.name,
    middle_name: userData.middle_name,
    last_name: userData.surname,
    mobile: userData.phone_no,
    alternate_phone_number: userData?.customer_profile?.alternate_phone_number,
    email: userData.email,
    user_id: userData.id,
    address: userData?.customer_profile?.address,
    bank: userData?.bank_account?.bank_id,
    id_no: userData.id_no,
    bank_branch: userData?.bank_account?.bank_branch_id,
    account_holder_name: userData?.bank_account?.account_name,
    account_number: userData?.bank_account?.account_number,
    holder_type: userData?.bank_account?.type?.toLowerCase() || "individual",
    distribution_choice: userData?.customer_profile?.distribution_choice || "M-Pesa",
  });

  const [companyData, setCompanyData] = useState({
    company_directors: [],
  });

  // Fetch bank branches based on the selected bank
  const fetchBranches = async (selectedBank) => {
    dispatch(getBankBranches(selectedBank));

    setBranchOptions(branches);
  };

  // Handle the switch change
  const handleDistributionChoiceChange = () => {
    const newChoice = distribution_choice === userData?.customer_profile?.distribution_choice; // Toggle between true and false
    console.log("newChoice", newChoice);
    setDistributionChoice(newChoice);
    // Update formData to reflect the new distribution choice
    setFormData((prevData) => ({
      ...prevData,
      distribution_choice: newChoice ? "M-Pesa" : "Bank",
    }));
  };

  useEffect(() => {
    if (formData.bank) {
      fetchBranches(formData.bank);
    } else {
      setBranchOptions([]);
    }
  }, [formData.bank]);

  const [savingProfile, setSavingProfile] = useState(false);
  const [savingNextOfKin, setSavingNextOfKin] = useState(false);
  const [savingDocuments, setSavingDocuments] = useState(false);
  const [idFrontImage, setIdFrontImage] = useState(null);
  // const [cr12Image, setCr12Image] = useState(null);
  const [idBackImage, setIdBackImage] = useState(null);

  const handleChangeNextOfKin = (e) => {
    const { name, value } = e.target;
    setKinData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleFileChange = (event, type) => {
    const file = event.target.files[0];
    if (type === "id_front") {
      setIdFrontImage(file);
    } else if (type === "id_back") {
      setIdBackImage(file);
    }
  };

  // const handleCR12Upload = (event, type) => {
  //   const file = event.target.files[0];
  //   if (type === "cr12_docs") {
  //     setCr12Image(file);
  //   }
  // };

  const handleSaveProfile = () => {
    setSavingProfile(true);
    onSaveProfile(formData)
      .then(() => {
        setSavingProfile(false);
      })
      .catch((error) => {
        console.error("Error saving profile data:", error);
        setSavingProfile(false);
      });
  };

  const handleSaveAllNextOfKin = () => {
    setSavingNextOfKin(true);

    // Map kinData to the required format
    const nextOfKinsPayload = kinData.map((kin) => ({
      name: kin.kinName,
      relationship: kin.kinRelationship,
      phone_no: kin.kinPhone,
      email: kin.kinEmail,
    }));

    const payload = {
      user_id: userData.id,
      nextOfKins: nextOfKinsPayload,
    };

    onSaveNextOfKin(payload)
      .then(() => {
        setSavingNextOfKin(false);
      })
      .catch((error) => {
        console.error("Error saving Next of Kin data:", error);
        setSavingNextOfKin(false);
      });
  };

  const handleSaveDocuments = () => {
    setSavingDocuments(true);

    const formData = new FormData();
    formData.append("user_id", userData.id);
    formData.append("id_front", idFrontImage);
    formData.append("id_back", idBackImage);

    onSaveDocuments(formData)
      .then(() => {
        setSavingDocuments(false);
        // handleNextStep();
      })
      .catch((error) => {
        console.error("Error saving documents:", error);
        setSavingDocuments(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log("value", e.target + " name: " + name);

    console.log("name: ", name + " value: " + value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeKin = (e, index) => {
    const { name, value } = e.target;
    const updatedKinData = [...kinData];
    updatedKinData[index] = {
      ...updatedKinData[index],
      [name]: value,
    };

    // Check if the relationship is "Father" or "Mother"
    if (name === "kinRelationship" && (value === "father" || value === "mother")) {
      // Check if the same relationship already exists for another next of kin
      const isDuplicate = updatedKinData.some(
        (kin, i) => i !== index && kin.kinRelationship === value
      );
      if (isDuplicate) {
        // If a duplicate is found, reset the value to an empty string
        updatedKinData[index][name] = "";
        // Optionally display a message to the user indicating the restriction
        alert(`Cannot select "${value}" relationship for both Father and Mother.`);
      }
    }

    setKinData(updatedKinData);
  };

  const handleDirectorChange = (e, index) => {
    const { name, value } = e.target;
    setCompanyData((prevCompanyData) => {
      const updatedDirectors = [...prevCompanyData.company_directors];
      updatedDirectors[index] = {
        ...updatedDirectors[index],
        [name]: value,
      };
      return {
        ...prevCompanyData,
        company_directors: updatedDirectors,
      };
    });
  };

  const handleAddDirector = () => {
    setCompanyata((prevFormData) => ({
      ...prevCompanyData,
      company_directors: [
        ...prevCompanyData.company_directors,
        { name: "", email: "", phone_number: "" }, // Initial empty director object
      ],
    }));
  };

  // Fetch bank details on component mount
  useEffect(() => {
    dispatch(getBankDetails());
  }, []);

  console.log("bank data", banks);

  return (
    <CardContent>
      {activeStep === 0 && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6}>
            <LayoutInput
              name="first_name"
              placeholder="First Name"
              size="large"
              value={formData.first_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LayoutInput
              name="middle_name"
              placeholder="Middle Name"
              size="large"
              value={formData.middle_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LayoutInput
              name="last_name"
              placeholder="Last Name"
              size="large"
              value={formData.last_name}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LayoutInput
              name="mobile"
              placeholder="Mobile"
              size="large"
              disabled
              value={formData.mobile}
              onChange={handleChange}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <LayoutInput
              name="id_no"
              placeholder="id Number"
              size="large"
              disabled
              value={formData.id_no}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LayoutInput
              value={formData.alternate_phone_number}
              onChange={handleChange}
              name="alternate_phone_number"
              placeholder="Alternate Phone Number"
              size="large"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LayoutInput
              name="email"
              placeholder="Email"
              size="large"
              disabled
              value={formData.email}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <LayoutInput
              name="address"
              placeholder="Address"
              size="large"
              value={formData.address}
              onChange={handleChange}
            />
          </Grid>
          {/* Bank Dropdown */}
          {/* <Grid item xs={12} sm={6}>
            <LayoutInput
              select
              value={formData?.bank ?? ""}
              name="bank"
              handleChange={handleChange}
              selectedValue={formData?.bank ?? ""}
              label="Bank"
              options={[
                { value: "Co-operatives", label: "Co-operatives" },
                { value: "Equity", label: "Equity" },
                { value: "NBK", label: "National Bank of Kenya" },
              ]}
            />
          </Grid> */}
          {/* Bank Dropdown */}
          <Grid item xs={12} sm={6}>
            <LayoutInput
              select
              value={formData?.bank ?? ""}
              name="bank"
              handleChange={handleChange}
              selectedValue={formData?.bank ?? ""}
              label="Bank"
              options={banks?.map((bank) => ({
                value: bank.id,
                label: bank.bank_name,
              }))}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <LayoutInput
              value={formData.account_number}
              onChange={handleChange}
              name="account_number"
              placeholder="Account Number"
              size="large"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <LayoutInput
              select
              value={formData.bank_branch ?? ""}
              handleChange={handleChange}
              selectedValue={formData?.bank_branch ?? ""}
              name="bank_branch"
              label="Bank Branch"
              options={branchOptions?.map((branch) => ({
                value: branch.id,
                label: branch.branch_name,
              }))}
            />
          </Grid>
          {/* Account Holder Name */}
          <Grid item xs={12}>
            <LayoutInput
              value={formData.account_holder_name}
              onChange={handleChange}
              name="account_holder_name"
              placeholder="Account Holder Name"
              size="large"
            />
          </Grid>
          {/* Holder Type Dropdown */}
          <Grid item xs={12}>
            <LayoutBox component="form" role="form">
              {console.log("holder_type data", formData.holder_type)}
              <LayoutInput
                id="holder_type"
                select
                name="holder_type"
                handleChange={handleChange}
                value={formData.holder_type ?? ""} // Use lowercase for the value
                selectedValue={formData.holder_type ?? ""} // Maintain the original casing for selected value
                label="Holder type"
                options={[
                  { value: "individual", label: "Individual" },
                  { value: "company", label: "Company" },
                ]}
              />
            </LayoutBox>
          </Grid>
          {/* Distribution Choice */}
          <Grid item xs={12} sm={12}>
            <LayoutBox display="flex" py={1} mb={0.25}>
              <LayoutBox mt={0.25}>
                <Switch
                  checked={distribution_choice === "M-Pesa"} // Toggle switch based on the current choice
                  onChange={handleDistributionChoiceChange}
                />
              </LayoutBox>
              <LayoutBox width="80%" ml={2}>
                <LayoutTypography variant="button" fontWeight="regular" color="text">
                  Choice distribution: {distribution_choice === "M-Pesa" ? "M-Pesa" : "Bank"}
                </LayoutTypography>
              </LayoutBox>
            </LayoutBox>
          </Grid>

          <Grid item xs={5}>
            <LayoutButton
              variant="contained"
              color="info"
              onClick={handleSaveProfile}
              disabled={savingProfile}
            >
              {savingProfile ? <CircularProgress size={24} /> : "Save"}
            </LayoutButton>
          </Grid>
        </Grid>
      )}
      {activeStep === 1 && (
        <Grid container spacing={2}>
          {kinData.map((kin, index) => (
            <Grid item xs={12} key={index}>
              {/* Next of Kin form fields */}
              <LayoutTypography variant="h6" color="secondary">
                {index === 0 ? "Primary Next of Kin" : "Secondary Next of Kin"}
              </LayoutTypography>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6}>
                  <LayoutInput
                    select
                    value={kin.kinRelationship}
                    selectedValue={kin.kinRelationship ?? ""}
                    handleChange={(e) => handleChangeKin(e, index)}
                    label="Relationship"
                    name="kinRelationship"
                    options={[
                      { value: "Mother", label: "Mother" },
                      { value: "Father", label: "Father" },
                      { value: "Child", label: "Child" },
                      { value: "Friend", label: "Friend" },
                    ]}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LayoutInput
                    name="kinName"
                    placeholder="Name"
                    size="large"
                    value={kin.kinName}
                    onChange={(e) => handleChangeKin(e, index)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LayoutInput
                    name="kinEmail"
                    placeholder="email"
                    size="large"
                    value={kin.kinEmail}
                    onChange={(e) => handleChangeKin(e, index)}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <LayoutInput
                    name="kinPhone"
                    placeholder="phone"
                    size="large"
                    value={kin.kinPhone}
                    onChange={(e) => handleChangeKin(e, index)}
                  />
                </Grid>

                {/* Save button for each next of kin */}
              </Grid>
            </Grid>
          ))}
          {/* Save button for all next of kin */}
          <Grid item xs={12}>
            <LayoutButton
              variant="contained"
              color="info"
              onClick={handleSaveAllNextOfKin}
              disabled={savingNextOfKin}
            >
              {savingNextOfKin ? <CircularProgress size={24} /> : "Save"}
            </LayoutButton>
          </Grid>
        </Grid>
      )}

      {activeStep === 2 && (
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} mb={4}>
            <LayoutTypography>Upload Documents </LayoutTypography>
          </Grid>

          <Grid item xs={12} sm={6}>
            <input
              accept="image/png, image/jpeg, image/jpg, image/webp"
              style={{ display: "none" }}
              id="id_front"
              type="file"
              onChange={(e) => handleFileChange(e, "id_front")}
            />
            <label htmlFor="id_front">
              <LayoutButton variant="contained" component="span" color="info">
                Upload ID Front
              </LayoutButton>
            </label>
            {idFrontImage ? (
              <img
                src={URL.createObjectURL(idFrontImage)} // Create object URL from file
                alt="ID Front"
                style={{ maxWidth: "100%", marginTop: "10px" }}
              />
            ) : userData?.customer_profile?.id_front_url ? ( // Fallback to URL if no file selected
              <img
                src={userData.customer_profile.id_front_url}
                alt="ID Front"
                style={{ maxWidth: "100%", marginTop: "10px" }}
              />
            ) : null}
          </Grid>

          <Grid item xs={12} sm={6}>
            <input
              accept="image/png, image/jpeg, image/jpg, image/webp"
              style={{ display: "none" }}
              id="id_back"
              type="file"
              onChange={(e) => handleFileChange(e, "id_back")}
            />
            <label htmlFor="id_back">
              <LayoutButton variant="contained" component="span" color="info">
                Upload ID Back
              </LayoutButton>
            </label>
            {idBackImage ? (
              <img
                src={URL.createObjectURL(idBackImage)} // Create object URL from file
                alt="ID Back"
                style={{ maxWidth: "100%", marginTop: "10px" }}
              />
            ) : userData?.customer_profile?.id_back_url ? ( // Fallback to URL if no file selected
              <img
                src={userData.customer_profile.id_back_url}
                alt="ID Back"
                style={{ maxWidth: "100%", marginTop: "10px" }}
              />
            ) : null}
          </Grid>
          {/* Save button for documents */}
          <Grid item xs={12} mt={4}>
            <LayoutButton
              variant="contained"
              color="info"
              onClick={handleSaveDocuments}
              disabled={savingDocuments}
            >
              {savingDocuments ? <CircularProgress size={24} /> : "Save Documents"}
            </LayoutButton>
          </Grid>
        </Grid>
      )}
      {/* You can add more conditions for additional steps if needed */}
    </CardContent>
  );
}

ProfileEditCard.propTypes = {
  userData: PropTypes.object.isRequired,
  onCancel: PropTypes.func.isRequired,
  activeStep: PropTypes.number.isRequired,
  onSaveProfile: PropTypes.func.isRequired,
  onSaveNextOfKin: PropTypes.func.isRequired,
  onSaveDocuments: PropTypes.func,
};

export default ProfileEditCard;
