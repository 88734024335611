

// Layout Dashboard 2 MUI helper functions
import pxToRem from "../../functions/pxToRem";

const dialogActions = {
  styleOverrides: {
    root: {
      padding: pxToRem(16),
    },
  },
};

export default dialogActions;
