/* eslint-disable react/prop-types */
// @mui material components
import Icon from "@mui/material/Icon";

// Layout Dashboard 2 MUI components
import LayoutBox from "../../../components/LayoutBox";
import LayoutTypography from "../../../components/LayoutTypography";
import LayoutProgress from "../../../components/LayoutProgress";

// Images
import logoSpotify from "../../../assets/images/small-logos/logo-spotify.svg";
import logoInvesion from "../../../assets/images/small-logos/logo-invision.svg";
import logoJira from "../../../assets/images/small-logos/logo-jira.svg";
import logoSlack from "../../../assets/images/small-logos/logo-slack.svg";
import logoWebDev from "../../../assets/images/small-logos/logo-webdev.svg";
import logoXD from "../../../assets/images/small-logos/logo-xd.svg";

function Completion({ value, color }) {
  return (
    <LayoutBox display="flex" alignItems="center">
      <LayoutTypography variant="caption" color="text" fontWeight="medium">
        {value}%&nbsp;
      </LayoutTypography>
      <LayoutBox width="8rem">
        <LayoutProgress value={value} color={color} variant="gradient" label={false} />
      </LayoutBox>
    </LayoutBox>
  );
}

const action = (
  <Icon sx={{ cursor: "pointer", fontWeight: "bold" }} fontSize="small">
    more_vert
  </Icon>
);

const LoansTableData = {
  columns: [
    { name: "Loan", align: "left" },
    { name: "LoanAmount", align: "left" },
    { name: "status", align: "left" },
    { name: "completion", align: "center" },
    { name: "action", align: "center" },
  ],

 
};

export default LoansTableData;
