// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Layout Dashboard 2 MUI components
import LayoutBox from "../../components/LayoutBox";
import LayoutTypography from "../../components/LayoutTypography";

// Layout Dashboard 2 MUI example components
import DashboardLayout from "../../widgets/LayoutContainers/DashboardLayout";
import Footer from "../../widgets/Footer";
import ProfileInfoCard from "../../widgets/Cards/InfoCards/ProfileInfoCard";
import ProfilesList from "../../widgets/Lists/ProfilesList";
import DefaultProjectCard from "../../widgets/Cards/ProjectCards/DefaultProjectCard";
import PlaceholderCard from "../../widgets/Cards/PlaceholderCard";

// Overview page components
import Header from "./components/Header";
import PlatformSettings from ".//components/PlatformSettings";

// Data
import { useSelector, useDispatch } from "react-redux";
import { useState } from "react";
import ProfileEdit from "../profile-edit";
import { useNavigate } from "react-router-dom";

const bgImage = "";

function Overview() {
  const userData = useSelector((state) => state?.users?.user);
  const [isEditingProfile, setIsEditingProfile] = useState(false); // State to track profile editing
  const navigate = useNavigate();

  const onClick = () => {
    navigate("/services");
  };
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (newValue) => {
    console.log(" tab value obtaines now", newValue);
    setTabValue(newValue);
  };

  const renderTabContent = () => {
    switch (tabValue) {
      case 0:
        return (
          <ProfileInfoCard
            title="profile information"
          
            description={`Hi  ${userData.name}`}
            info={{
              fullName: userData?.name+" "+userData?.middle_name+" "+userData.surname,
              mobile: userData.phone_no,
              id_number: userData.id_no,
              email: userData.email,
              // location: "",
            }}
            action={{ route: "/profile", tooltip: "Edit Profile" }}
          />
        );
      case 1:
        return (
          <>
            <Card sx={{ height: "100%" }}>
              <LayoutBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={3}
                pb={3}
                px={2}
              >
                <LayoutTypography variant="h6" fontWeight="medium">
                  Coming Soon
                </LayoutTypography>
              </LayoutBox>
            </Card>
          </>
        );
      case 2:
        return (
          <>
           <Card sx={{ height: "100%" }}>
              <LayoutBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={3}
                pb={3}
                px={2}
              >
                <LayoutTypography variant="h6" fontWeight="medium">
                  Coming Soon
                </LayoutTypography>
              </LayoutBox>
            </Card>
          </>
        );
      case 3:
        return (
          <>
            <Card sx={{ height: "100%" }}>
              <LayoutBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={3}
                pb={3}
                px={2}
              >
                <LayoutTypography variant="h6" fontWeight="medium">
                  Coming Soon
                </LayoutTypography>
              </LayoutBox>
            </Card>
          </>
        );
      default:
        return null;
    }
  };

  const handleEditProfileClick = () => {
    setIsEditingProfile(true); // Set the state to indicate profile editing
  };

  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.info.main, 0.6),
            rgba(gradients.info.state, 0.6)
          )}, url(${bgImage})`,
        backgroundPositionY: "50%",
      }}
    >
      {/* <Header /> */}
      <Header
        handleTabChange={handleTabChange}
        tabValue={tabValue}
        tabsOrientation={tabsOrientation}
        setTabsOrientation={setTabsOrientation}
      />
      {/* Show ProfilesEditCard if editing profile, else show ProfileInfoCard */}
      {isEditingProfile ? (
        <LayoutBox mt={5} mb={3}>
          <Grid container item xs={12} md={12} xl={12}>
            <ProfileEdit
              userData={userData}
              onCancel={() => setIsEditingProfile(false)} // Handle cancel editing
            />
          </Grid>
        </LayoutBox>
      ) : (
        <LayoutBox mt={5} mb={3}>
          <Grid container spacing={3}>
            {/* <Grid item xs={12} md={6} xl={4}>
            <PlatformSettings />
          </Grid> */}
            <Grid item xs={12} md={12} xl={12}>
              {renderTabContent()}
            </Grid>
            {/* <Grid item xs={12} xl={4}>
            <ProfilesList title="conversations" profiles={profilesListData} />
          </Grid> */}
          </Grid>
        </LayoutBox>
      )}
      <LayoutBox mb={3}>
        <Card>
          <LayoutBox pt={2} px={2}>
            <LayoutBox mb={0.5}>
              <LayoutTypography variant="h6" fontWeight="medium">
                Available Loan Options
              </LayoutTypography>
            </LayoutBox>
            <LayoutBox mb={1}>
              <LayoutTypography variant="button" fontWeight="regular" color="text">
                Quick secured Loans
              </LayoutTypography>
            </LayoutBox>
          </LayoutBox>
          <LayoutBox p={2}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={"assets/images/black-muslim-woman.png"}
                  label="#1"
                  title="Log Book Loan"
                  description="Get fast financing by using your vehicle's logbook as collateral, with flexible repayment options and competitive interest rates."
                  action={{
                    type: "internal",
                    route: "/loan-calculator",
                    color: "info",
                    label: "Apply now",
                  }}
                  authors={[]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={"assets/images/auto-mechanics.png"}
                  label=" #2"
                  title="Business Loans "
                  description="Receive the capital necessary to expand your business and manage cash flow with flexible repayment options."
                  action={{
                    type: "internal",
                    route: "/loan-calculator",
                    color: "info",
                    label: "Apply now",
                  }}
                  authors={[]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <DefaultProjectCard
                  image={"assets/images/visiting-car-dealership.png"}
                  label="#3"
                  title="Asset Finance"
                  description="Utilize your vehicle as collateral for loans to acquire or upgrade assets with flexible repayment terms."
                  action={{
                    type: "internal",
                    route: "/loan-calculator",
                    color: "info",
                    label: "Apply now",
                  }}
                  authors={[]}
                />
              </Grid>
              <Grid item xs={12} md={6} xl={3}>
                <PlaceholderCard
                  title={{ variant: "h5", text: "View More" }}
                  outlined
                  onClick={onClick}
                />
              </Grid>
            </Grid>
          </LayoutBox>
        </Card>
      </LayoutBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Overview;
