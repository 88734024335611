

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Layout Dashboard 2 MUI components
import LayoutBox from "../../../../components/LayoutBox";
import LayoutTypography from "../../../../components/LayoutTypography";

function Invoice({ date, id, price, noGutter }) {
  return (
    <LayoutBox
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      py={1}
      pr={1}
      mb={noGutter ? 0 : 1}
    >
      <LayoutBox lineHeight={1.125}>
        <LayoutTypography display="block" variant="button" fontWeight="medium">
          {date}
        </LayoutTypography>
        <LayoutTypography variant="caption" fontWeight="regular" color="text">
          {id}
        </LayoutTypography>
      </LayoutBox>
      <LayoutBox display="flex" alignItems="center">
        <LayoutTypography variant="button" fontWeight="regular" color="text">
          {price}
        </LayoutTypography>
        <LayoutBox
          display="flex"
          alignItems="center"
          lineHeight={1}
          ml={3}
          sx={{ cursor: "pointer" }}
        >
          <Icon fontSize="small">picture_as_pdf</Icon>
          <LayoutTypography variant="button" fontWeight="bold">
            &nbsp;PDF
          </LayoutTypography>
        </LayoutBox>
      </LayoutBox>
    </LayoutBox>
  );
}

// Setting default values for the props of Invoice
Invoice.defaultProps = {
  noGutter: false,
};

// Typechecking props for the Invoice
Invoice.propTypes = {
  date: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  price: PropTypes.string.isRequired,
  noGutter: PropTypes.bool,
};

export default Invoice;
