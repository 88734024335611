import { forwardRef, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for LayoutInput
// import LayoutInputRoot from "components/LayoutInput/LayoutInputRoot";

// Layout Dashboard 2 MUI context
import { useLayoutController } from "../../context";
import LayoutInputRoot from "./LayoutInputRoot";
import { InputBase, InputLabel, MenuItem, Select } from "@mui/material";
import CustomSelect, { LayoutInputSelectRoot, StyledSelect } from "./LayoutInputSelectRoot";

const LayoutInput = forwardRef(
  (
    {
      size,
      error,
      success,
      disabled,
      name,
      select,
      options,
      label,
      displayEmpty,
      selectedValue,
      handleChange,
      ...rest
    },
    ref
  ) => {
    const [controller] = useLayoutController();
    const { darkMode } = controller;

    // const [searchTerm, setSearchTerm] = useState("");

    if (select) {
      // Render select input if select prop is true
      return (
        <CustomSelect
          value={selectedValue} // Example value
          displayEmpty
          name={name}
          handleChange={handleChange}
          options={options}
          label={label}
          ownerState={{
            size: size,
            error: error,
            success: success,
            iconDirection: "left",
            disabled: false,
            darkMode: darkMode,
            asSelect: true,
          }}
        />
      );
    }

    // Otherwise, render text input by default
    return (
      <LayoutInputRoot
        name={name}
        {...rest}
        ref={ref}
        ownerState={{ size, error, success, disabled, darkMode, asSelect: false }}
      />
    );
  }
);
// Setting default values for the props of LayoutInput
LayoutInput.defaultProps = {
  size: "medium",
  error: false,
  displayEmpty: true,
  success: false,
  disabled: false,
  select: false,
  options: [],
};

// Typechecking props for the LayoutInput
LayoutInput.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  error: PropTypes.bool,
  displayEmpty: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
  selectedValue: PropTypes.string,
  handleChange: PropTypes.func,
  select: PropTypes.bool,
  label: PropTypes.string,
  name: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.any.isRequired,
      label: PropTypes.string.isRequired,
    })
  ),
};

export default LayoutInput;
