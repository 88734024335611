import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CardContent from "@mui/material/CardContent";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import MenuItem from "@mui/material/MenuItem";
import LayoutInput from "../../../../components/LayoutInput";
import { FormControl } from "react-bootstrap";
import { InputLabel, Select, Switch } from "@mui/material";
import LayoutBox from "../../../../components/LayoutBox";
import LayoutTypography from "../../../../components/LayoutTypography";
import LayoutButton from "../../../../components/LayoutButton";
import { LayoutToolTip } from "../../../../components/LayoutToolTip";

function ProfileBusinessCard({ userData, onSaveCompany }) {
  const [formData, setFormData] = useState({
    user_id: userData.id,
    company_name: "",
    company_address: "",
    company_email: "",
    company_description: "",
    cr_12_docs: "",
    company_directors: [{ name: "", email: "", phone_number: "" }],
  });

  const [savingCompany, setSavingCompany] = useState(false);
  const [cr12Image, setCr12Image] = useState(null);

  const handleCR12Upload = (event, type) => {
    const file = event.target.files[0];
    if (type === "cr12_docs") {
      setCr12Image(file);
      setFormData((prevData) => ({
        ...prevData,
        [type]: file,
      }));
    }
  };

  const handleSaveCompany = () => {
    setSavingCompany(true);
    onSaveCompany(formData)
      .then(() => {
        setSavingCompany(false);
      })
      .catch((error) => {
        console.error("Error saving profile data:", error);
        setSavingCompany(false);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;

    console.log("value", e.target + " name: " + name);

    console.log("name: ", name + " value: " + value);
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleDirectorChange = (e, index) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => {
      const updatedDirectors = [...prevFormData.company_directors];
      updatedDirectors[index] = {
        ...updatedDirectors[index],
        [name]: value,
      };
      return {
        ...prevFormData,
        company_directors: updatedDirectors,
      };
    });
  };

  const handleAddDirector = () => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      company_directors: [
        ...prevFormData.company_directors,
        { name: "", email: "", phone_number: "" }, // Initial empty director object
      ],
    }));
  };
  const handleRemoveDirector = () => {
    setFormData((prevFormData) => {
      const updatedDirectors = [...prevFormData.company_directors];
      if (updatedDirectors.length > 1) {
        updatedDirectors.pop();
      }
      return {
        ...prevFormData,
        company_directors: updatedDirectors,
      };
    });
  };
  return (
    <CardContent>
        
      <Grid container spacing={2}>
        {/* Conditionally render additional fields if holder_type is "company" */}
        <Grid item xs={12}>
          <LayoutTypography
            variant={"h2"}
            fontWeight="medium"
            textTransform="capitalize"
            textAlign="center"
          >
            Company information
          </LayoutTypography>
        </Grid>
        <>
          <Grid item xs={12}>
            {/* Company Name */}
            <LayoutInput
              value={formData.company_name}
              onChange={handleChange}
              name="company_name"
              placeholder="Company Name"
              size="large"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            {/* Company Name */}
            <LayoutInput
              value={formData.company_name}
              onChange={handleChange}
              name="company_email"
              placeholder="Company Email"
              size="large"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            {/* address of Company */}
            <LayoutInput
              value={formData.company_address}
              onChange={handleChange}
              name="company_address"
              placeholder="address of Company"
              size="large"
            />
          </Grid>
          <Grid item xs={12}>
            {/* address of Company */}
            <LayoutInput
              multiline
              rows={4}
              value={formData.company_description}
              onChange={handleChange}
              name="company_description"
              placeholder="Company Description"
              size="large"
            />
          </Grid>
          {/*  */}
          <Grid item xs={12}>
            <LayoutTypography
              variant={"h4"}
              fontWeight="small"
              textTransform="capitalize"
              textAlign="start"
            >
              Upload documents
            </LayoutTypography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <input
              accept="image/png, image/jpeg, image/jpg, image/webp, application/pdf"
              style={{ display: "none" }}
              id="cr12_docs"
              type="file"
              onChange={(e) => handleCR12Upload(e, "cr12_docs")}
            />
            <label htmlFor="cr12_docs">
              <LayoutButton variant="contained" component="span" color="info">
                Upload Cr12 Doc
              </LayoutButton>
            </label>

            {cr12Image && (
              <img
                src={URL.createObjectURL(cr12Image)}
                alt="Cr12 Docs"
                style={{ maxWidth: "100%", marginTop: "10px" }}
              />
            )}
          </Grid>
          <Grid item xs={12}>
            {/* Company Directors */}
            {/* Iterate over company directors array and render inputs for each director */}
            {formData.company_directors.map((director, index) => (
              <LayoutBox key={index} mb={3}>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6}>
                    <LayoutInput
                      value={director.name}
                      onChange={(e) => handleDirectorChange(e, index)}
                      name={`director_name_${index}`}
                      placeholder="Director Name"
                      size="large"
                    />
                  </Grid>
                  {/* Other director fields */}
                  <Grid item xs={12} sm={6}>
                    <LayoutInput
                      value={director.email}
                      onChange={(e) => handleDirectorChange(e, index)}
                      name={`director_email_${index}`}
                      placeholder="Director Email"
                      size="large"
                    />
                  </Grid>
                  <Grid item xs={12} sm={6}>
                    <LayoutInput
                      value={director.phone}
                      onChange={(e) => handleDirectorChange(e, index)}
                      name={`director_phone_${index}`}
                      placeholder="Director Phone"
                      size="large"
                    />
                  </Grid>
                </Grid>
              </LayoutBox>
            ))}
            {/* Button to add more directors */}
            <LayoutBox mt={4}>
              <Grid container spacing={2}>
                <Grid item xs={6} sm={4}>
                  <LayoutToolTip
                    title={"Add Directors"}
                    color="white"
                    background="info"
                    disableFocusListener
                  >
                    <LayoutButton onClick={handleAddDirector} color="warning">
                      Add Director
                    </LayoutButton>
                  </LayoutToolTip>
                </Grid>
                <Grid item xs={6} sm={4}>
                  <LayoutToolTip
                    title={
                      formData.company_directors.length === 1
                        ? "Disabled, Atleast one director is needed"
                        : "Remove Director fields"
                    }
                    color="white"
                    background="white"
                    disableFocusListener
                  >
                    <LayoutButton
                      onClick={handleRemoveDirector}
                      color={formData.company_directors.length === 1 ? "secondary" : "dark"}
                      disabled={formData.company_directors.length === 1}
                    >
                      Remove Director Field
                    </LayoutButton>
                  </LayoutToolTip>
                </Grid>
              </Grid>
            </LayoutBox>
          </Grid>
        </>

        <Grid item xs={5}>
          <LayoutButton
            variant="contained"
            color="info"
            onClick={handleSaveCompany}
            disabled={savingCompany}
          >
            {savingCompany ? <CircularProgress size={24} /> : "Save"}
          </LayoutButton>
        </Grid>
      </Grid>

      {/* You can add more conditions for additional steps if needed */}
    </CardContent>
  );
}

ProfileBusinessCard.propTypes = {
  userData: PropTypes.object.isRequired,
  onSaveCompany: PropTypes.func.isRequired,
};

export default ProfileBusinessCard;
