import { styled } from "@mui/material/styles";

export const LayoutStepperRoot = styled("div")(({ theme, ownerState }) => {
  const { size, error, success, iconDirection, disabled, darkMode, asSelect } = ownerState;
  const { palette, functions, typography, borders, boxShadows } = theme;
  const { inputColors, grey, white, transparent, info, text, dark } = palette;
  const { inputBoxShadow } = boxShadows;
  const { pxToRem, boxShadow } = functions;
  const { size: fontSize } = typography;
  const { borderRadius } = borders;

  // styles for the input with size="small"
  const smallStyles = () => ({
    fontSize: fontSize.xs,
    padding: `${pxToRem(4)} ${pxToRem(12)}`,
  });

  // styles for the input with size="large"
  const largeStyles = () => ({
    padding: pxToRem(12),
  });

  // Define your styles based on the ownerState
  return {
    backgroundColor: theme.palette.mode === "dark" ? theme.palette.grey[700] : text.transparent,
    zIndex: 1,
    color: "#fff",
    outline:"none",
    "&:hover, &:focus, &:active": {
        outline:"none!important",
      },
    // width: "100%",
    // // height: 50,
    // display: "flex",
    // borderRadius: "50%",
    // justifyContent: "center",
    // alignItems: "center",
    ...(ownerState.active && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
      boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
    }),
    ...(ownerState.completed && {
      backgroundImage:
        "linear-gradient( 136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)",
    }),
  };
});
