import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { createUser, requestOtp } from "../../store/users.store";
import { Formik, Form, Field, ErrorMessage, useFormikContext } from "formik";
import { ToastContainer } from "react-toastify";
import Notify from "../../utils/Notify";

//send otp

const Register = () => {
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  const loading = useSelector((state) => state.users.loading);
  const dispatch = useDispatch();

  const [startDate, setStartDate] = useState(new Date());

  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // height: '100vh',
    // marginBottom:"3cm"
  };

  const formStyle = {
    // width: '350px',
    padding: "20px",
    background: "white",
    // boxShadow: '0 0 10px rgba(0, 0, 0, 0.2)',
    textAlign: "left",
  };

  const formGroupStyle = {
    marginBottom: "15px",
    marginTop: "-20px",
    marginTop: "0",
    textAlign: "left",
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleFormSubmit = (values, { setSubmitting }) => {
    console.log("The from the register form are : ", values);

    dispatch(createUser(values)).then((result) => {
      console.log("The result from the store action is ", result);
      if (result?.payload?.success === true) {
        navigate("/auth");
      }

      //if successfull dispatch the otp
    });

    setSubmitting(false);
  };

  return (
    <div className="item item-13 container-fluid">
      <div className="img-fill row">
        <nav className="navbar navbar-expand-lg" style={{ position: "relative", zIndex: "1000" }}>
          <div className="container">
            <Link className="navbar-brand" to="/">
              <img
                src="./assets/images/logo.png"
                alt="quick sava logo"
                style={{ width: "6.5cm" }}
              />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarResponsive"
              aria-controls="navbarResponsive"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarResponsive">
              <ul className="navbar-nav ml-auto">
                <li className="nav-item active">
                  <Link to="/" className="nav-link">
                    Home
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </nav>

        <div className="row">
          <div className="col-md-6 col-sm-12 service_heading">
            <div style={containerStyle}>
              <div className="auth service_heading service-size" style={formStyle}>
                <h1>
                  <strong
                    style={{
                      fontFamily: "trueno",
                    }}
                  >
                    Sign Up
                  </strong>
                </h1>
                <p className="text-dark">
                  Already have an account ?{" "}
                  <Link to="/auth" style={{ color: "#ff8b00" }}>
                    Sign in
                  </Link>
                </p>
                <Formik
                  initialValues={{
                    email: "",
                    phone_no: "+254",
                    password: "",
                    name: "",
                    surname: "",
                    middle_name: "",
                    id_no: "",
                    otp_code: "",
                    gender: "",
                  }}
                  onSubmit={handleFormSubmit}
                >
                  <Form>
                    <div className="row">
                      <div style={formGroupStyle} className="col-md-6">
                        <Field
                          type="text"
                          name="name"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Name"
                          style={{ borderRadius: "55px" }}
                          required
                        />
                      </div>

                      <div style={formGroupStyle} className="col-md-6">
                        <Field
                          type="text"
                          name="surname"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="Surname"
                          style={{ borderRadius: "55px" }}
                          required
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div style={formGroupStyle} className="col-md-6">
                        <Field
                          type="text"
                          name="middle_name"
                          className="form-control"
                          id="exampleInputMiddleName1"
                          placeholder="Middle Name"
                          style={{ borderRadius: "55px" }}
                          required
                        />
                      </div>
                      <div style={formGroupStyle} className="col-md-6">
                        <Field
                          type="text"
                          name="id_no"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="ID NO"
                          style={{ borderRadius: "55px" }}
                          required
                        />
                      </div>

                      <div style={formGroupStyle} className="col-md-7">
                        <Field
                          type="tel"
                          name="phone_no"
                          className="form-control"
                          id="exampleInputEmail1"
                          placeholder="+254"
                          style={{ borderRadius: "55px" }}
                          required
                        />
                      </div>
                      <OTPButton />
                      <div style={formGroupStyle} className="col-md-6">
                        <Field
                          type="tel"
                          name="otp_code"
                          className="form-control"
                          id="exampleOtpInput"
                          placeholder="OTP"
                          style={{ borderRadius: "55px" }}
                          required
                        />
                      </div>

                      <div style={formGroupStyle} className="col-md-6">
                        <Field
                          as="select"
                          name="gender"
                          className="form-control"
                          id="genderSelect"
                          style={{ borderRadius: "55px" }}
                          required
                        >
                          <option value="">Select Gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                        </Field>
                      </div>
                    </div>

                    <div style={formGroupStyle}>
                      <Field
                        type="email"
                        name="email"
                        className="form-control"
                        id="exampleInputPassword"
                        placeholder="Email"
                        style={{ borderRadius: "55px" }}
                        required
                      />
                    </div>

                    <div style={formGroupStyle}>
                      <Field
                        type="password"
                        name="password"
                        className="form-control"
                        id="exampleInputPassword1"
                        placeholder="Password"
                        style={{ borderRadius: "55px" }}
                        required
                      />
                    </div>

                    <button
                      type="submit"
                      className="btn  w-100 text-light"
                      disabled={loading}
                      style={{ borderRadius: "5px", backgroundColor: "#ff8b00", fontSize: ".6cm" }}
                    >
                      <strong>Sign Up</strong>
                    </button>
                  </Form>
                </Formik>
              </div>
            </div>
          </div>

          {/* <div className="col-md-6  text-light justify-content-between">
            <div className="container products p-5">
              <div className="row justify-content-between">
                <div className="col-md-6 p-2 mx-auto">
                  <Link to="/autologbook-loans" className="text-light service-card">
                    <img
                      src="./assets/images/logbook_loans.png"
                      alt="Logbook Loans"
                      style={{
                        height: "3cm",
                        // marginRight:"50%"
                      }}
                    />
                    <h6 className="text-start mt-3">Quick Logbook Loans</h6>
                  </Link>
                </div>
                <div className="col-md-6 p-2">
                  <Link to="/checkoff-loans" className="text-light service-card ">
                    <img
                      src="./assets/images/checkoff_loans.png"
                      alt="Logbook Loans"
                      style={{
                        height: "3cm",
                        // marginRight:"50%"
                      }}
                    />
                    <h6 className="mt-3">Quick Check Off Loans</h6>
                  </Link>
                </div>
              </div>

              <div className="row justify-content-between">
                <div className="col-md-6 p-2 text-start">
                  <Link to="/import-loans" className="text-light service-card text-start">
                    <img
                      src="./assets/images/import_loans.png"
                      alt="Logbook Loans"
                      style={{
                        height: "3cm",
                        marginRight: "50%",
                      }}
                    />
                    <h6 className="text-start mt-3">Quick Drive Loans</h6>
                  </Link>
                </div>
                <div className="col-md-6 p-2">
                  <Link to="/personal-loans" className="text-light service-card">
                    <img
                      src="./assets/images/personal_loans.png"
                      alt="Logbook Loans"
                      style={{
                        height: "3cm",
                        marginRight: "50%",
                      }}
                    />
                    <h6 className="mt-3">Quick Biashara Loans</h6>
                  </Link>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>

      <ToastContainer style={{ zIndex: "1", position: "absolute", top: "100px" }} />
    </div>
  );
};

export default Register;

const OTPButton = () => {
  const dispatch = useDispatch();
  const { values } = useFormikContext(); // Access form values here
  const loading = useSelector((state) => state.users.otp_loading);

  const handleRequestOtp = (phone_no) => {
    console.log("Requesting OTP for phone number:", phone_no);
    // Ensure phone_no is valid before dispatching
    if (!phone_no || phone_no.trim() === "") {
      Notify({status:400, message:"Please enter a valid phone number."});
      return;
    }

    // Dispatching the action with the correct payload format
    dispatch(requestOtp({ phone_no: phone_no }))
      .then((result) => {
        console.log("The result from the store action is ", result);

        if (result?.payload?.success === true) {
          Notify({status: 200, message: "OTP has been sent to your phone number."})
        } else {
          Notify({status: 400, message: "Failed to send OTP. Please try again"})

        }
      })
      .catch((error) => {
        console.error("Error requesting OTP:", error);
        Notify({status:400, message:"An error occurred while requesting OTP. Please try again later."});
      });
  };

  return (
    <div className="col-md-5 mb-3">
      <button
        type="button"
        className="btn btn-verify"
        style={{ borderRadius: "55px", width: "100%" }}
        onClick={() => handleRequestOtp(values.phone_no)} // Use values here
      >
         {loading ? (
          <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
        ) : (
          "Request OTP"
        )}
      </button>
    </div>
  );
};
