import Header from "./Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { getFromLocalStorage } from "../utils/local-storage";
import Footer from "./Footer";
import VisionMissionValues from "./coreDescription";

const About = () => {
  const user = getFromLocalStorage("user");
  const location = useLocation();
  const navigate = useNavigate();

  return (
    <div style={{ backgroundColor: "#fff" }} className="">
      <div className="item item-7 hero-banner-about">
        <Header />
        <div className="img-fill ">
          <section className="" id="">
            <div className="container ">
              <div className="row ">
                <div className="col-md-5 "></div>
                <div className="col-md-7" style={{ zIndex: "5" }}>
                  <div className="section-heading service_heading text-dark">
                    <h4
                      style={{ fontSize: "3em", letterSpacing: "-0.03em", font: "trueno" }}
                      className="mb-0 mt-0 my-3"
                    >
                      ABOUT QUICKSAVA
                    </h4>

                    <h3
                      style={{
                        fontSize: ".6cm",
                        letterSpacing: "0.05em",
                        fontWeight: "normal",
                        marginTop: "1cm",
                      }}
                      className="mb-0"
                    >
                      At Quick Sava, your financial success is our top priority.
                      <br />
                      We offer fast, reliable access to funds for a range of needs—whether it is for
                      business growth, asset acquisition, personal expenses, or emergency
                      situations. <br/>We believe in more than just providing financial solutions. Our
                      mission is to build lasting relationships with our valued clients, fostering
                      growth and prosperity together. 
                      <br/>Join Quick Sava today, and let us achieve your
                      financial goals hand in hand!
                      <br />
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div className="row justify-content-end mt-5" style={{ zIndex: "7", position: "relative" }}>
          <VisionMissionValues />
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default About;
