import { Link } from "react-router-dom";
import LayoutBadge from "../../../components/LayoutBadge";
import LayoutBox from "../../../components/LayoutBox";
import LayoutButton from "../../../components/LayoutButton";
import LayoutTypography from "../../../components/LayoutTypography";
import { Tooltip } from "@mui/material";
import { LayoutToolTip } from "../../../components/LayoutToolTip";

// const ProfileSettingsData = [
//   {
//     color: "info",
//     icon: <i className="ni ni-mobile-button" style={{ fontSize: "10px" }} />,
//     name: "Profile Info",
//     description: (
//       <>
//         <LayoutToolTip title="id number has not been provided" color="warning" disableFocusListener>
//           <LayoutTypography
//             color="warning"
//             variant="h6"
//             fontWeight="regular"
//             onClick={(e) => {
//               e.preventDefault();
//               e.stopPropagation();
//             }}
//             sx={{
//               lineHeight: 0,
//               transition: "all 0.2s cubic-bezier(.34,1.61,.7,1.3)",
//               p: 0.5,

//               "&:hover, &:focus": {
//                 transform: "translateX(5px)",
//               },
//             }}
//           >
//             Incomplete
//           </LayoutTypography>
//         </LayoutToolTip>
//       </>
//     ),
//     route: "",
//     onCategoryClick: 0,
//   },

//   {
//     color: "info",
//     icon: <i className="ni ni-box-2" style={{ fontSize: "12px" }} />,
//     name: "Next Of Kin",
//     description: (
//       <>
//         {/* <LayoutTypography color="success" variant="h6" fontWeight="regular">
//           Complete
//         </LayoutTypography> */}
//         <LayoutToolTip
//           title="All Required items are available"
//           color="success"
//           disableFocusListener
//         >
//           <LayoutTypography
//             color="success"
//             variant="h6"
//             fontWeight="regular"
//             onClick={(e) => {
//               e.preventDefault();
//               e.stopPropagation();
//             }}
//             sx={{
//               lineHeight: 0,
//               transition: "all 0.2s cubic-bezier(.34,1.61,.7,1.3)",
//               p: 0.5,

//               "&:hover, &:focus": {
//                 transform: "translateX(5px)",
//               },
//             }}
//           >
//             Complete
//           </LayoutTypography>
//         </LayoutToolTip>
//       </>
//     ),
//     route: "",
//   },
//   {
//     color: "info",
//     icon: <i className="ni ni-tag" style={{ fontSize: "12px" }} />,
//     name: "Upload",
//     description: (
//       <>
//         {/* <LayoutTypography color="success" variant="h6" fontWeight="regular">
//           Complete
//         </LayoutTypography> */}
//         {/* <LayoutBox >
//             <LayoutTypography variant="caption" color="text" fontWeight="medium">
//               {" "}
//               Complete
//             </LayoutTypography>
//           </LayoutBox> */}
//         <LayoutToolTip
//           title="Incomplete missing idFront and idBack docs/image"
//           color="warning"
//           disableFocusListener
//         >
//           <LayoutTypography
//             color="success"
//             variant="h6"
//             fontWeight="regular"
//             onClick={(e) => {
//               e.preventDefault();
//               e.stopPropagation();
//             }}
//             sx={{
//               lineHeight: 0,
//               transition: "all 0.2s cubic-bezier(.34,1.61,.7,1.3)",
//               p: 0.5,

//               "&:hover, &:focus": {
//                 transform: "translateX(5px)",
//               },
//             }}
//           >
//             Incomplete
//           </LayoutTypography>
//         </LayoutToolTip>
//       </>
//     ),
//     route: "",
//   },
// ];
const ProfileSettingsData = [
  {
    color: "info",
    icon: <i className="ni ni-mobile-button" style={{ fontSize: "10px" }} />,
    name: "profile_status",  // Match the name to your localStorage keys
    label: "Profile Info",
    description: "",
    completionStatus: "Incomplete", // default value, to be updated later
    route: "",
    onCategoryClick: 0,
  },
  {
    color: "info",
    icon: <i className="ni ni-box-2" style={{ fontSize: "12px" }} />,
    name: "kin_status",  // Match the name to your localStorage keys
    label: "Next Of Kin",
    description: "",
    completionStatus: "Incomplete", // default value, to be updated later
    route: "",
  },
  {
    color: "info",
    icon: <i className="ni ni-tag" style={{ fontSize: "12px" }} />,
    name: "upload_status",  // Match the name to your localStorage keys
    label: "Upload",
    description: "",
    completionStatus: "Incomplete", // default value, to be updated later
    route: "",
  },
];

export default ProfileSettingsData;
