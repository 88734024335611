import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
import { setLocalStorage, getFromLocalStorage } from "../utils/local-storage";
import Notify from "../utils/Notify";

const initialState = {
  data: [],
  loading: false,
  status: "",
  banks:[],
  bank_branches:[],
  authenticated: false,
};

export const getBankDetails= createAsyncThunk("banks/allBanks", async (thunkAPI) => {
  try {
    return await api.bankDetails();
  } catch (e) {
    Notify({ status: 421, message: e });

    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const getBankBranches = createAsyncThunk("banks/bankBranches", async (payload, thunkAPI) => {
  try {
    return await api.bankBranches(payload);
  } catch (e) {
    Notify({ status: 421, message: e });

    return thunkAPI.rejectWithValue(e.response.data);
  }
});


const banksSlice = createSlice({
  name: "banks",
  initialState,
  extraReducers: (builder) => {
    //Notification messages based on responses
    builder.addCase(getBankDetails.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(getBankDetails.fulfilled, (state, action) => {
      state.loading = false;
      state.status = "success";
      state.banks=action.payload.data
     
    });
    builder.addCase(getBankDetails.rejected, (state, action) => {
      state.loading = false;
      state.status = "error";
      state.errors = action?.payload?.errors;
      Notify({ status: 421, message: action?.payload?.errors });
    });

    builder.addCase(getBankBranches.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(getBankBranches.fulfilled, (state, action) => {
      state.loading = false;
      state.status="success"
      state.bank_branches=action.payload.data
      
    });
    builder.addCase(getBankBranches.rejected, (state, action) => {
      state.loading = false;
      state.errors = action?.payload?.errors;

      Notify({ status: 421, message: action?.payload?.errors });
    });

   
  },
});

export default banksSlice.reducer;
