import client from "./client";

export default {
  async profileInfo(data) {
    return client.parseResponse(await client.post("/profile/profile-info", data));
  },
  async nextOfKin(data) {
    return client.parseResponse(await client.post("/profile/nextOfKins/create", data));
  },

  async uploadDocs(data) {
    return client.parseResponse(await client.post("/profile/upload_profile_documents", data));
  },
};
