import client from "./client";

export default {

    async bankDetails() {        
        return client.parseResponse(await client.get('/banks/all'))
    },

    async bankBranches(branch_id){
        return client.parseResponse( await client.get(`/banks/details/${branch_id}/branches`))
    },

}
