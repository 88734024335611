
/** 
  All of the routes for the Soft UI Dashboard React are added here,
  You can add a new route, customize the routes and delete the routes here.
  Once you add a new route on this file it will be visible automatically on
  the Sidenav.
  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that has other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Layout Dashboard 2 MUI layouts
// import Dashboard from "layouts/dashboard";
// import Tables from "layouts/tables";
import Billing from "./layouts/billing";
// import VirtualReality from "layouts/virtual-reality";
// import RTL from "layouts/rtl";
// import Profile from "layouts/profile";
// import SignIn from "layouts/authentication/sign-in";
// import SignUp from "layouts/authentication/sign-up";

// Layout Dashboard 2 MUI components
import LayoutBox from "./components/LayoutBox";
import Home from "./components/Home";
import Overview from "./layouts/profile/index";
import ProfileEdit from "./layouts/profile-edit";
import Tables from "./layouts/tables";

const routes = [
  
  {
    type: "route",
    name: "Dashboard",
    key: "dashboard",
    route: "/dashboard",
    icon: <LayoutBox component="i" color="primary" fontSize="14px" className="ni ni-tv-2" />,
    component: <Overview />,
  },
{
    type: "route",
    name: "Loans",
    key: "loans",
    route: "/loans",
    icon: (
      <LayoutBox component="i" color="warning" fontSize="14px" className="ni ni-calendar-grid-58" />
    ),
    component: <Tables/>,
  },
  // {
  //   type: "route",
  //   name: "Billing",
  //   key: "billing",
  //   route: "/billing",
  //   icon: <LayoutBox component="i" color="success" fontSize="14px" className="ni ni-credit-card" />,
  //   component: <Billing />,
  // },
  {
    type: "route",
    name: "Home",
    key: "home",
    route: "/",
    icon: <LayoutBox component="i" color="primary" fontSize="14px" className="ni ni-archive-2" />,
    component: <></>,
  },
  { type: "title", title: "Profile", key: "account-pages" },
  {
    type: "route",
    name: "Profile",
    key: "profile",
    route: "/profile",
    icon: <LayoutBox component="i" color="dark" fontSize="14px" className="ni ni-single-02" />,
    component: <ProfileEdit />,
  },
  
    {
    type: "route",
    name: "Logout",
    key: "logout",
    route: "/logout",
    icon: (
      <LayoutBox component="i" color="warning" fontSize="14px" className="ni ni-button-power" />
    ),
    component: <></>,
  },

];

export default routes;
