import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// Custom styles for LayoutToolTip

// Layout Dashboard 2 MUI context
import { useLayoutController } from "../../context";
import LayoutTooltipRoot from "./LayoutTooltipRoot";
// import { LayoutToolTipRoot } from "./LayoutTooltipRoot";

export const LayoutToolTip = forwardRef(
  (
    {
      color,
      background,
      fontWeight,
      textTransform,
      verticalAlign,
      textGradient,
      opacity,
      children,
      ...rest
    },
    ref
  ) => {
    const [controller] = useLayoutController();
    const { darkMode } = controller;

    return (
      <LayoutTooltipRoot
        {...rest}
        ref={ref}
        ownerState={{
          color,
          background,
          textTransform,
          verticalAlign,
          fontWeight,
          opacity,
          textGradient,
          darkMode,
        }}
      >
        {children}
      </LayoutTooltipRoot>
    );
  }
);

// Setting default values for the props of LayoutToolTip
LayoutToolTip.defaultProps = {
  color: "dark",
  background: "white",
  fontWeight: false,
  textTransform: "none",
  verticalAlign: "unset",
  textGradient: false,
  opacity: 1,
};

// Typechecking props for the LayoutToolTip
LayoutToolTip.propTypes = {
  color: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text",
    "white",
  ]),
  background: PropTypes.oneOf([
    "inherit",
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
    "text",
    "white",
  ]),
  fontWeight: PropTypes.oneOf([false, "light", "regular", "medium", "bold"]),
  textTransform: PropTypes.oneOf(["none", "capitalize", "uppercase", "lowercase"]),
  verticalAlign: PropTypes.oneOf([
    "unset",
    "baseline",
    "sub",
    "super",
    "text-top",
    "text-bottom",
    "middle",
    "top",
    "bottom",
  ]),
  textGradient: PropTypes.bool,
  children: PropTypes.node.isRequired,
  opacity: PropTypes.number,
};
