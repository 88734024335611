

import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Layout Dashboard 2 MUI components


// Layout Dashboard 2 MUI context
import { useLayoutController, setLayout } from "../../../context";
import LayoutBox from "../../../components/LayoutBox";

function DashboardLayout({ bgColor, children, ...rest }) {
  const [controller, dispatch] = useLayoutController();
  const { miniSidenav, darkMode } = controller;

  

  const background = darkMode && !bgColor ? "transparent" : bgColor;

  return (
    <LayoutBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        p: 3,

        [breakpoints.up("xl")]: {
          marginLeft: miniSidenav ? pxToRem(120) : pxToRem(274),
          transition: transitions.create(["margin-left", "margin-right"], {
            easing: transitions.easing.easeInOut,
            duration: transitions.duration.standard,
          }),
        },
      })}
    >
      <LayoutBox
        bgColor={background || "info"}
        height="300px"
        width="100vw"
        position="absolute"
        top={0}
        left={0}
        sx={darkMode && { bgColor: ({ palette: { background } }) => background.default }}
        {...rest}
      />
      {children}
    </LayoutBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  bgColor: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;
