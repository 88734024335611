// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";

// Layout Dashboard 2 MUI components
import LayoutBox from "../../components/LayoutBox";
import LayoutTypography from "../../components/LayoutTypography";

// Layout Dashboard 2 MUI example components
import DashboardLayout from "../../widgets/LayoutContainers/DashboardLayout";
import Footer from "../../widgets/Footer";

// Overview page components
import Header from "./components/Header";
import PlatformEdit from "./components/PlatformSettings";
import { useEffect, useRef, useState } from "react";
import CategoriesList from "../../widgets/Lists/CategoriesList";
import ProfileSettingsData from "./data";
import PlatFormBusiness from "./components/Biashara";
import { LayoutToolTip } from "../../components/LayoutToolTip";
import { getFromLocalStorage } from "../../utils/local-storage";
import { ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";
// import { Notifications } from "@mui/icons-material";

const bgImage = "";

function ProfileEdit() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const platformSettingsRef = useRef(null);
  const platformBusinessRef = useRef(null);
  const [profileData, setProfileData] = useState(ProfileSettingsData);
  const completionStatus = getFromLocalStorage("status_completion");
  const [stepIndex, setStepIndex] = useState(0);
  const status_completion=useSelector((state)=>state.users.status_completion)

  const handleCategoryClick = (index) => {
    setStepIndex(index); // Store the index
    setTabValue(1); // Switch to the Profile tab
  };

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };
  // const handleCategoryClick = (index) => {
  //   setTabValue(1); // Assuming 1 is the index for the profile tab
  //   if (platformSettingsRef.current) {
  //     console.log("Updating step to:", index);
  //     platformSettingsRef.current.updateStep(index);
  //   } else {
  //     console.error("platformSettingsRef is null");
  //   }
  // };

  useEffect(() => {
    if (platformSettingsRef.current) {
      console.log("platformSettingsRef is set");
    } else {
      console.error("platformSettingsRef is null on initial render");
    }
  }, []);

  useEffect(() => {
    // Retrieve completion status from localStorage
    console.log("dataStatus api", completionStatus);

    if (completionStatus) {
      console.log("completionStatus", ProfileSettingsData);

      const updatedProfileData = ProfileSettingsData.map((item, index) => {
        // Ensure we have the matching keys in completionStatus
        const statusItem = completionStatus[item.name] || {};
        console.log("stausItem", statusItem);

        return {
          ...item,
          completionStatus: statusItem?.status || item.completionStatus, // Get status from localStorage if exists
          description: (
            <LayoutToolTip
              title={
                statusItem.status === "completed"
                  ? "All required items are available"
                  : statusItem?.missing_fields
              }
              color={statusItem.status === "completed" ? "success" : "warning"}
              disableFocusListener
            >
              <LayoutTypography
                color={statusItem.status === "completed" ? "success" : "warning"}
                variant="h6"
                fontWeight="regular"
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
                sx={{
                  lineHeight: 0,
                  transition: "all 0.2s cubic-bezier(.34,1.61,.7,1.3)",
                  p: 0.5,
                  "&:hover, &:focus": {
                    transform: "translateX(5px)",
                  },
                }}
              >
                {statusItem.status || item.completionStatus}
              </LayoutTypography>
            </LayoutToolTip>
          ),
        };
      });

      setProfileData(updatedProfileData);
    }
  }, []);

  const renderTabContent = () => {
    switch (tabValue) {
      case 0:
        return (
          <Grid item xs={12} md={12}>
            <CategoriesList
              title="Profile Settings"
              categories={profileData}
              onCategoryClick={handleCategoryClick}
            />
          </Grid>
        );
      case 1:
        return (
          <PlatformEdit
            ref={platformSettingsRef}
            initialIndex={stepIndex}
            setTabValue={setTabValue}
          />
        );

      case 2:
        return <PlatFormBusiness ref={platformBusinessRef} />;

      case 3:
        return (
          <>
            <Card sx={{ height: "100%" }}>
              <LayoutBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={3}
                pb={3}
                px={2}
              >
                <LayoutTypography variant="h6" fontWeight="medium" color="secondary">
                  Coming Soon
                </LayoutTypography>
              </LayoutBox>
            </Card>
          </>
        );
      case 4:
        return (
          <>
            <Card sx={{ height: "100%" }}>
              <LayoutBox
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                pt={3}
                pb={3}
                px={2}
              >
                <LayoutTypography variant="h6" fontWeight="medium" color="secondary">
                  Coming Soon
                </LayoutTypography>
              </LayoutBox>
            </Card>
          </>
        );
      default:
        return null;
    }
  };
  return (
    <DashboardLayout
      sx={{
        backgroundImage: ({ functions: { rgba, linearGradient }, palette: { gradients } }) =>
          `${linearGradient(
            rgba(gradients.info.main, 0.6),
            rgba(gradients.info.state, 0.6)
          )}, url(${bgImage})`,
        backgroundPositionY: "50%",
      }}
    >
      {/* <Header /> */}
      <Header
        handleTabChange={handleTabChange}
        tabValue={tabValue}
        tabsOrientation={tabsOrientation}
        setTabsOrientation={setTabsOrientation}
      />
      {/* <Notifications /> */}
      <ToastContainer style={{zIndex:"1", position: "absolute", top:"100px"}}/>


      <LayoutBox mt={5} mb={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={12} xl={12}>
            {/* <Different rendered items  /> */}
            {renderTabContent()}
          </Grid>
        </Grid>
      </LayoutBox>
      <Footer />
    </DashboardLayout>
  );
}

export default ProfileEdit;
