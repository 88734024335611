import Header from "./Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Slider from "react-slick";
import { useEffect, useState } from "react";
import { getFromLocalStorage } from "../utils/local-storage";
import Footer from "./Footer";

const Home = () => {
  const location = useLocation();
  const user = getFromLocalStorage("user");
  const navigate = useNavigate();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const updateWindowWidth = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", updateWindowWidth);
    updateWindowWidth();
    return () => {
      window.removeEventListener("resize", updateWindowWidth);
    };
  }, []);

  return (
    <div className="" style={{ backgroundColor: "#fff" }}>
      <div className="item item-1 home">
        <Header />

        <div className="row ">
          <div className="col-md-6 col-sm-12 service_heading" style={{ zIndex: "5" ,position:"relative"}}>
            <div className="container p-5">
              <h4 className="service_heading ">
                EMPOWER YOUR <br />
                BUSINESS WITH QUICKSAVA CREDIT         
              </h4>
              <p
                style={{ lineHeight: "1.3", opacity: ".8", letterSpacing: "-0.03em" }}
                className="mt-5 brief mb-5"
              >
                where your financial growth takes center stage! <br />
                We offer fast, flexible, and affordable loans 
                <br/>tailored to fuel your business and personal success.
                <br />
                <br />
                Partner with us today <br/>let’s thrive together!                
              </p>

              <button
                style={{
                  width: windowWidth > 768 ? "5cm" : "100%",
                  borderRadius: "10px",
                  backgroundColor: "#ff8b00",
                  fontSize: ".6cm",
                  marginBottom: "6cm",
                  fontFamily: "trueno",
                }}
                className="btn p-2 mx-auto text-light"
                onClick={() => navigate("/loan-calculator")}
              >
                <strong className="home_btn_txt">Apply Now</strong>
              </button>
            </div>
          </div>
          <div className="col-md-6  text-light justify-content-between ">
            <div className="container-xl products p-5 hero-banner mx-2">
              <div className="row justify-content-between  "></div>
              {/* <div className="row justify-content-between">
                <div className="col-md-6 p-2 mx-auto">
                  <Link to="/autologbook-loans" className="text-light service-card">
                    <img src="./assets/images/logbook_loans.png" alt="Logbook Loans"
                      style={{
                        height: "3cm",
                        // marginRight:"50%"
                      }}
                    />
                    <h6 className="text-start mt-3">Quick Logbook Loans</h6>
                  </Link>
                </div>
                <div className="col-md-6 p-2">
                  <Link to="/checkoff-loans" className="text-light service-card ">
                    <img src="./assets/images/checkoff_loans.png" alt="Logbook Loans"
                      style={{
                        height: "3cm",
                        // marginRight:"50%"

                      }}
                    />
                    <h6 className="mt-3">Quick Check Off Loans</h6>
                  </Link>
                </div>

              </div>

              <div className="row justify-content-between">
                <div className="col-md-6 p-2 text-start">
                  <Link to="/import-loans" className="text-light service-card text-start">
                    <img src="./assets/images/import_loans.png" alt="Logbook Loans"
                      style={{
                        height: "3cm",
                        marginRight: "50%"

                      }}
                    />
                    <h6 className="text-start mt-3">Quick Drive Loans</h6>
                  </Link>
                </div>
                <div className="col-md-6 p-2">
                  <Link to="/personal-loans" className="text-light service-card" >
                    <img src="./assets/images/personal_loans.png" alt="Logbook Loans"
                      style={{
                        height: "3cm",
                        marginRight: "50%"

                      }}
                    />
                    <h6 className="mt-3">Quick Biashara Loans</h6>
                  </Link>

                </div>

              </div> */}

              {/* <div className="row mt-5">
                <div className="col-6 mt-5"></div>


                <div className="col-6 home_abt" style={{ marginTop: "1.7cm" }}>
                  <h5>About Us</h5>
                  <p style={{
                    lineHeight: "1.3", opacity: "0.8",
                    fontSize: ".3cm"
                  }} className="align-self text-left">
                    QuickSava is a non-bank financial services company<br />
                    registered in Kenya. We offer Log Book loans and Import loans <br />
                    for employed individuals<br />
                    We aspire to serve your financial needs quickly, Transparently and<br />
                    be understanding
                  </p>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
