import { createAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
import { setLocalStorage, getFromLocalStorage } from "../utils/local-storage";
import Notify from "../utils/Notify";
import { useNavigate } from "react-router-dom";

const initialState = {
  data: [],
  loading: false,
  status: "",
  otp_loading: false,
  user: {},
  status_completion: {},
  authenticated: false,
};

export const createUser = createAsyncThunk("user/auth/signup", async (payload, thunkAPI) => {
  try {
    // return await api.registerUser(state?.users?.register_form)
    return await api.registerUser(payload);
  } catch (e) {
    Notify({ status: 421, message: e });

    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const requestOtp = createAsyncThunk("user/auth/request-otp", async (payload, thunkAPI) => {
  try {
    return await api.requestOtp(payload);
  } catch (e) {
    Notify({ status: 421, message: e });

    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const sendOtp = createAsyncThunk("user/code", async (payload, thunkAPI) => {
  try {
    return await api.verificationCode(payload);
  } catch (e) {
    Notify({ status: 421, message: e });

    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const resetPassword = createAsyncThunk("user/reset-password", async (payload, thunkAPI) => {
  try {
    console.log("Forgot password form");

    return await api.resetPassword(payload);
  } catch (e) {
    Notify({ status: 421, message: e });

    return thunkAPI.rejectWithValue(e.response.data);
  }
});

//asynchronous function , action , callback
export const loginUser = createAsyncThunk("user/auth/login", async (payload, thunkAPI) => {
  try {
    return await api.login(payload);
  } catch (e) {
    Notify({ status: 421, message: e });

    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const resetState = createAction("users/reset", (stateToReset) => {
  return { payload: stateToReset };
});

export const setState = createAction("users/setAuthState", (stateToSet, data) => {
  return { payload: { stateToSet, data } };
});

const usersSlice = createSlice({
  name: "users",
  initialState,
  extraReducers: (builder) => {
    builder.addCase(setState, (state, action) => {
      const { stateToSet, data } = action.payload;
      if (state.hasOwnProperty(stateToSet)) {
        state[stateToSet] = data;
      }
      state.error = null;
    });
    builder.addCase(resetState, (state, action) => {
      const stateToReset = action.payload;
      if (state.hasOwnProperty(stateToReset)) {
        state[stateToReset] = initialState.auth[stateToReset];
      }
      state.error = null;
    });
    //Notification messages based on responses
    builder.addCase(createUser.fulfilled, (state, action) => {
      console.log("The action payload is ", action);
      state.loading = false;
      state.user = action?.payload;
      state.status = "success";
      // Notify({status: 200, message: action?.payload?.message})

      if (action?.payload?.success === true) {
        Notify({ status: 200, message: action?.payload?.message });
      } else if (action?.payload?.success === false) {
        Notify({ status: 421, message: action?.payload?.errors || action?.payload?.message });
      }
    });

    builder.addCase(createUser.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(createUser.rejected, (state, action) => {
      state.loading = false;
      state.status = "error";
      state.errors = action?.payload?.errors;
      Notify({ status: 421, message: action?.payload?.errors });
    });

    builder.addCase(requestOtp.fulfilled, (state, action) => {
      console.log("The action payload is ", action);
      state.loading = false;
      state.otp_loading = false;
      state.status = "success";
      // Notify({status: 200, message: action?.payload?.message})

      if (action?.payload?.success === true) {
        Notify({ status: 200, message: action?.payload?.message });
      } else if (action?.payload?.success === false) {
        Notify({ status: 421, message: action?.payload?.errors });
      }
    });

    builder.addCase(requestOtp.pending, (state, action) => {
      state.loading = true;
      state.otp_loading = true;
    });

    builder.addCase(requestOtp.rejected, (state, action) => {
      state.loading = false;
      state.otp_loading = false;
      state.status = "error";
      state.errors = action?.payload?.errors;
      Notify({ status: 421, message: action?.payload?.errors });
    });

    builder.addCase(resetPassword.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(resetPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.reset_password_form = action?.payload;
      if (action?.payload?.success === true) {
        Notify({ status: 200, message: action?.payload?.message });
      } else if (action?.payload?.success === false) {
        Notify({ status: 421, message: action?.payload?.message });
      }
    });

    builder.addCase(resetPassword.rejected, (state, action) => {
      state.loading = false;
    });

    //pending API call to login user , action, callback
    builder.addCase(loginUser.pending, (state, action) => {
      state.loading = true;
    });

    //after user has successfully been logged in
    builder.addCase(loginUser.fulfilled, (state, action) => {
      state.loading = false;
      state.authenticated = true;

      if (action?.payload?.success === false) {
        Notify({ status: 421, message: action?.payload?.message });
      } else if (action?.payload?.success === true) {
        state.status = "success";
        state.user = action?.payload?.user;
        state.status_completion = action?.payload?.status_completion;
        state.authenticated = true;
        console.log("The result from loggin in the user is ", action);
        Notify({ status: 200, message: action?.payload?.message });
        setLocalStorage("status_completion", action?.payload?.status_completion);
        setLocalStorage("user", action?.payload?.user);
        setLocalStorage("token", action?.payload?.access_token);
      }

      // const remember = getFromLocalStorage('remember_me')
      // if(remember){
      //     setLocalStorage('user', action?.payload?.user,5684000000)
      // }else{
      //     setLocalStorage('user', action?.payload?.user)
      // }
    });

    //if a user is not successfully logged in
    builder.addCase(loginUser.rejected, (state, action) => {
      state.loading = false;
      state.status = "error";
      state.errors = action?.payload?.errors;
      Notify({ status: 421, message: action?.payload?.message });
    });

    //Verification
    builder.addCase(sendOtp.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success === false) {
        Notify({ status: 421, message: action?.payload?.message });
      }

      setTimeout(() => {
        Notify({ status: 200, message: action?.payload?.message });
      }, 1000);
    });

    builder.addCase(sendOtp.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(sendOtp.rejected, (state, action) => {
      state.loading = false;
      Notify({ status: 421, message: action?.payload?.message });
    });
  },
});

export default usersSlice.reducer;
