import React,{ useState,useRef,useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import {useSelector,useDispatch} from "react-redux";
import { getFromLocalStorage } from "../utils/local-storage";
import { Formik, Form, Field, ErrorMessage } from "formik";

import Header from "./Header";

import Footer from "./Footer";



const Referral =()=>{
  const dispatch = useDispatch();
  const user = getFromLocalStorage("user"); 
  const navigate = useNavigate();
  const [loanResult,setLoanResult] = useState([]);
  const [rangeValue, setRangeValue] = useState(50000);
  const [installments, setInstallmentsValue] = useState(1);
  const [loanInstallmentAmount,setLoanInstallmentAmount] = useState(0);
  const inputRef = useRef(null);


   return(
    <>

 
    
    <div className="item item-20">
    <Header/>

      <div className="img-fill px-5">

        <div className="" style={{marginBottom:"2cm"}}> 
                


          <div className="service_heading">
            <div className="">
              <div className="row justify-content-between">
                <div className="col-md-6 hero-banner-placeholder"></div>
                  <div className="" >
                    <h4 className="mt-5" style={{letterSpacing: "-0.05em",textTransform:"none"}}>Referral Program</h4>
                    <p className="text-center text-dark"
                    style={{fontSize:".4cm", letterSpacing: "0.06em", lineHeight:"1.6em"}}
                    >QuickSava&apos;s Microfinance Referal program rewards customers <br/>for introducing friends
                    and family to our quick financial services. <br/>
                    Referrers and their refferals receive incentives like reduced interest
                    <br/>rates and cash bonuses. It&apos;s our way of thanking our community 
                    <br/>for spreading our speedy financial support to more individual <br/>
                    and fostering financial empowerement </p>

                    <button type="button" style={{ background: '#ff8b00', width:"7cm",padding: '10px', borderRadius: '5px', cursor: 'pointer' }}
                          className="mx-auto h5 mt-5"
                          onClick={()=>navigate("#refferal")}
                          data-bs-toggle="modal" data-bs-target="#exampleModal"
                        >
                          <strong>JOIN NOW</strong>
                        </button>
      
           
                </div>
              </div>

         
            </div>
          </div>




        </div>
      </div>
        
      
  
    </div>



<div className="modal fade w-100" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg w-100">
    <div className="modal-content">
          <div className="modal-body w-100">


            <div className="container bg-light text-dark" id="refferal">

            <form className="bg-light">
              <h5>Refereee Details</h5>
              <div className="row justify-content-between">
              <div className="col-md-6  w-100  align-items-stretch my-4">
                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Referee Name"/>
              </div>
              <div className="col-md-6 w-100  align-items-stretch my-4">
                <input type="password" className="form-control" id="exampleInputPassword1"  placeholder="Email Address"/>
              </div>

              <div className="col-md-6 w-100  align-items-stretch">
                <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Mobile Number"/>
              </div>

              <div className="col-md-6 w-100  align-items-stretch">
                <input type="password" className="form-control" id="exampleInputPassword1" placeholder="ID Number"/>
              </div>
            </div>


            <h5 className="mt-5">Refered Details</h5>
            <div className="row justify-content-between ">
              <div className="col-md-6  w-100  align-items-stretch my-4">
                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Name of the Reffered"/>
              </div>
              <div className="col-md-6  w-100  align-items-stretch my-4">
                <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Email Address"/>
              </div>

              <div className="col-md-6  w-100  align-items-stretch">
                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Mobile Number"/>
              </div>
              <div className="col-md-6  w-100  align-items-stretch">
                <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Loan Amount Requested"/>
              </div>


            </div>


            <div className="mb-3 form-check mt-3">
              <input type="checkbox" className="form-check-input" id="exampleCheck1"/>
              {/*  eslint-disable-next-line react/no-unknown-property */}
              <label className="form-check-label" for="exampleCheck1">Agree to the <Link to="/terms-and-conditions" style={{color:"#ff8b00"}}>terms and conditions</Link></label>
            </div>
            <button type="submit" className="btn text-dark w-100" style={{marginBottom:"1cm", background:"#ff8b00"}}>Submit Details</button>
            </form>
          <button type="button" className="btn text-dark" data-bs-dismiss="modal" aria-label="Close"
          style={{background:"#ff8b00"}}
          >Close</button>

            </div>
          </div>
    </div>
  </div>
</div>


  

    
    <Footer/>

    </>
    )
}

export default Referral;