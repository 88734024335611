import React from "react";

// Import JSON data
import visionMissionData from "../assets/data/values.json";

const VisionMissionValues = () => {
  return (
    <div className="row col-sm-12 col-md-12 col-xl-10">
      {visionMissionData.map((item, index) => (
        <div key={index} className="col-md-4 mb-5 text-center">
          <div className="row justify-content-center align-items-center">
            <img
              src={item.image}
              alt={item.title}
              style={{ width: "100%", maxWidth: "30px", height: "auto" }}
            />
            &nbsp;
            <h5 style={{ fontSize: "1.5em", fontWeight: "bold", color: "#333" }}>{item.title}</h5>
          </div>

          <p style={{ fontSize: "0.7em", color: "#555", marginTop: "0.5rem" }}>{item.description}</p>
        </div>
      ))}
    </div>
  );
};

export default VisionMissionValues;
