

// Layout Dashboard 2 MUI helper functions
import pxToRem from "../../theme-dark/functions/pxToRem";

// Layout Dashboard 2 MUI base styles
import colors from "../../theme-dark/base/colors";
import boxShadows from "../../theme-dark/base/boxShadows";
import borders from "../../theme-dark/base/borders";

const { transparent } = colors;
const { lg } = boxShadows;
const { borderRadius } = borders;

const popover = {
  styleOverrides: {
    paper: {
      backgroundColor: transparent.main,
      boxShadow: lg,
      padding: pxToRem(8),
      borderRadius: borderRadius.lg,
    },
  },
};

export default popover;
