import { forwardRef } from "react";
import { AppBar, Card, Container, Grid, Tab, Tabs } from "@mui/material";
import { useSelector } from "react-redux";

import ProfileBusinessCard from "../../../../widgets/Cards/InfoCards/ProfileBusinessCard";

const PlatFormBusiness = forwardRef((props, ref) => {
  const userData = useSelector((state) => state?.users?.user);

  const handleSaveCompany = (profileData) => {
    // Placeholder function for onSaveCompany
    return new Promise((resolve, reject) => {
      // Replace this with your actual API call to save profile data
      setTimeout(() => {
        console.log("Profile data saved:", profileData);
        resolve();
      }, 1000);
    });
  };

  return (
    <Card>
      <Container sx={{ mt: 3, mb: 3 }} maxWidth="xl">
        <Grid container spacing={4}>
          {/* Profile card */}

          <Grid item xs={12} md={6} lg={4} sx={{ ml: "auto" }}>
            <AppBar position="static">
              {/* <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
                <Tab
                  label="Limited Liability company"
                  icon={
                    <i
                      className="ni ni-archive-2"
                      style={{ marginTop: "6px", marginRight: "8px" }}
                    />
                  }
                />
                <Tab
                  label="SME"
                  icon={
                    <i className="ni ni-diamond" style={{ marginTop: "6px", marginRight: "8px" }} />
                  }
                />
                <Tab
                  label="App"
                  icon={
                    <i className="ni ni-app" style={{ marginTop: "6px", marginRight: "8px" }} />
                  }
                />

                <Tab
                  label="Settings"
                  icon={
                    <i
                      className="ni ni-settings-gear-65"
                      style={{ marginTop: "6px", marginRight: "8px" }}
                    />
                  }
                />
              </Tabs> */}
            </AppBar>
          </Grid>
          <Grid item xl={12} xs={12}>
            <ProfileBusinessCard userData={userData} onSaveCompany={handleSaveCompany} />
          </Grid>
        </Grid>
      </Container>
    </Card>
  );
});

export default PlatFormBusiness;
