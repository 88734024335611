import React, { forwardRef } from "react";
import { LayoutSliderRoot } from "./LayoutSliderRoot";
import PropTypes from "prop-types";

const LayoutSlider = forwardRef(
  (
    {
      size = "medium",
      defaultValue,
      valueLabelDisplay = "auto",
      className,
      step = 1,
      max = 100,
      min = 0,
      value,
      onChange,
      style,
      ...rest
    },
    ref
  ) => {
    return (
      <LayoutSliderRoot
        ref={ref}
        size={size}
        defaultValue={defaultValue}
        valueLabelDisplay={valueLabelDisplay}
        className={className}
        step={step}
        max={max}
        min={min}
        value={value}
        onChange={onChange}
        style={style}
        {...rest}
      />
    );
  }
);
LayoutSlider.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  defaultValue: PropTypes.number,
  valueLabelDisplay: PropTypes.oneOf(["on", "auto", "off"]),
  className: PropTypes.string,
  step: PropTypes.number,
  max: PropTypes.number,
  min: PropTypes.number,
  value: PropTypes.number,
  onChange: PropTypes.func,
  style: PropTypes.object,
};

export default LayoutSlider;
