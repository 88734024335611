import { useState, useEffect, useMemo, useCallback } from "react";

// react-router components
import { Routes, Route, useLocation, useNavigate } from "react-router-dom";
import React from "react";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

// Layout Dashboard 2 MUI components
import Home from "../components/Home";
import Services from "../components/Services";
import Contact from "../components/Contact";
import About from "../components/about";
import LoanCalculator from "../components/LoanCalculator";
import Login from "../components/auth/Login";
import Register from "../components/auth/Register";
import Verify from "../components/auth/Verify";
import ForgotPassword from "../components/auth/ForgotPassword";
import Account from "../components/dashboard/Account";
import ImportLoans from "../components/ImportLoans";
import CheckOffLoans from "../components/CheckOffLoans";
import AutoLogbookLoans from "../components/AutoLogbookLoans";
import PersonalLoans from "../components/PersonalLoans";
import Referral from "../components/Referral";
import TermsAndConditions from "../components/TermsAndCoditions";
// Layout Dashboard 2 MUI example components

// Layout Dashboard 2 MUI themes
import themeRTL from "../assets/theme/theme-rtl";
import themeDarkRTL from "../assets/theme-dark/theme-rtl";

// RTL plugins
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";

// Layout Dashboard 2 MUI routes

// Layout Dashboard 2 MUI contexts
import { setLayout, useLayoutController } from "../context";

// Icon Fonts
import "../assets/css/nucleo-icons.css";
import "../assets/css/nucleo-svg.css";
import LoanDetails from "./LoanDetails";
const ProtectedRoute = React.lazy(() => import("../utils/ProtectedRoute"));

export default function HomeLayout() {
  const [controller, dispatch] = useLayoutController();
  const { direction, darkMode } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    console.log("pathname information", pathname);

    setLayout(dispatch, "home");
  }, [pathname]);
  const [rtlCache, setRtlCache] = useState(null);
  const Logout = () => {
    let navigate = useNavigate();
    const out = useCallback(() => {
      localStorage.clear();
      navigate("/");
    }, [navigate]);

    useEffect(() => {
      out();
    }, [out]);
    return null;
  };
  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: "rtl",
      stylisPlugins: [rtlPlugin],
    });

    setRtlCache(cacheRtl);
  }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute("dir", direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  return (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />

        <Routes>
          <Route path={"/auth"} element={<Login />}></Route>
          <Route path={"/register"} element={<Register />}></Route>
          <Route path={"/forgot-password"} element={<ForgotPassword />}></Route>
          <Route path={"/verify"} element={<Verify />}></Route>
          <Route path={"/import-loans"} element={<ImportLoans />}></Route>
          <Route path={"/details"} element={<LoanDetails />}></Route>
          <Route path={"/checkoff-loans"} element={<CheckOffLoans />}></Route>
          <Route
            path={"/autologbook-loans"}
            element={<AutoLogbookLoans />}
          ></Route>
          <Route path={"/personal-loans"} element={<PersonalLoans />}></Route>
          <Route path={"/home"} element={<Home />}></Route>
          <Route path={"/"} element={<Home />}></Route>

          <Route path={"/contact"} element={<Contact />}></Route>
          <Route path={"/referal"} element={<Referral />}></Route>

          <Route path={"/services"} element={<Services />}></Route>

          <Route path={"/about"} element={<About />}></Route>

          <Route
            path={"/terms-and-conditions"}
            element={<TermsAndConditions />}
          ></Route>
          {/* <Route path={"/loan-calculator"} element={<LoanCalculator />}></Route> */}
          <Route
            path={"/account"}
            element={
              <ProtectedRoute>
                <Account />
              </ProtectedRoute>
            }
          ></Route>
          {/* <Route path={"/dashboard"} element={<Dashboard />}></Route> */}

          <Route path={"/logout"} element={<Logout />} />
        </Routes>
      </ThemeProvider>
    </CacheProvider>
  );
}
