import users from './users.api'
import loans from './loans.api'
import profile from './profile.api'
import banks from './banks.api'
export default {
    ...users,
    ...loans,
    ...profile,
    ...banks

}

