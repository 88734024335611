import React from "react";
import { useNavigate } from "react-router-dom";
import loans from '../../assets/data/loans.json'; // Adjust the path to your actual location

const LoanItems = () => {
  const navigate = useNavigate();

  return (
    <div className="row">
      {loans.map((loan) => (
        <div key={loan.id} className="col-lg-3 col-md-12 col-xl-3 mb-5">
          <div className="service-item">
            <div className="image-container">
              <img src={loan.image} alt={loan.title} onClick={() => navigate(loan?.readMoreLink)} style={{cursor: 'pointer'}}/>
            </div>
            <div className="content-container text-dark">
              <h5 className="my-2 mb-2 title-underline">{loan.title}</h5>
              <p className="text-dark mt-2 service-item-text">{loan.summary}</p>
            </div>
            <button
              className="apply-button filled-button btn"
              onClick={() => navigate("/loan-calculator")}
            >
              <strong>Apply Now</strong>
            </button>
          </div>
        </div>
      ))}
    </div>
  );
};

export default LoanItems;
