// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// Layout Dashboard 2 MUI components
import LayoutBox from "../../../components/LayoutBox";
import LayoutTypography from "../../../components/LayoutTypography";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
function CategoriesList({ title, categories, onCategoryClick }) {
  const renderItems = categories.map(({ color, icon, name, description, route }, key) => (
    <LayoutBox
      key={name}
      component="li"
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      borderRadius="lg"
      py={2}
      pr={2}
      // onClick={() => onCategoryClick(key)}
      onClick={() => {
        if (onCategoryClick) {
          onCategoryClick(key);
        }
      }}
      mb={categories.length - 1 === key ? 0 : 1}
    >
      <LayoutBox display="flex" alignItems="center">
        <LayoutBox
          display="grid"
          alignItems="center"
          justifyContent="center"
          bgColor={color}
          borderRadius="lg"
          shadow="md"
          color="white"
          width="2rem"
          height="2rem"
          mr={2}
          variant="gradient"
        >
          <Icon
            sx={{
              display: "grid",
              placeItems: "center",
            }}
          >
            {icon}
          </Icon>
        </LayoutBox>
        <LayoutBox display="flex" flexDirection="column">
          <LayoutTypography variant="h6" color={color} fontWeight="bold" gutterBottom pl={1}>
            {name}
          </LayoutTypography>
          <LayoutTypography variant="caption" color="text" fontWeight="light">
            {description}
          </LayoutTypography>
        </LayoutBox>
      </LayoutBox>
      <LayoutBox display="flex">
        {route ? (
          <LayoutBox display="flex">
            <LayoutTypography
              component={Link}
              variant="button"
              color={color}
              to={route}
              sx={{
                lineHeight: 0,
                transition: "all 0.2s cubic-bezier(.34,1.61,.7,1.3)",
                p: 0.5,
                "&:hover, &:focus": {
                  transform: "translateX(5px)",
                },
              }}
            ></LayoutTypography>
          </LayoutBox>
        ) : (
          <LayoutBox
            variant="contained"
            color={color}
            sx={{
              lineHeight: 0,
              transition: "all 0.2s cubic-bezier(.34,1.61,.7,1.3)",
              p: 0.5,
              "&:hover, &:focus": {
                transform: "translateX(5px)",
              },
            }}
          >
            <ChevronRightIcon fontSize="medium" />
          </LayoutBox>
        )}
      </LayoutBox>
    </LayoutBox>
  ));

  return (
    <Card>
      <LayoutBox pt={3} px={2}>
        <LayoutTypography variant="h6" fontWeight="medium" textTransform="capitalize">
          {title}
        </LayoutTypography>
      </LayoutBox>
      <LayoutBox p={2}>
        <LayoutBox component="ul" display="flex" flexDirection="column" p={0} m={0}>
          {renderItems}
        </LayoutBox>
      </LayoutBox>
    </Card>
  );
}

// Typechecking props for the CategoriesList
CategoriesList.propTypes = {
  title: PropTypes.string.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object).isRequired,
  onCategoryClick: PropTypes.func,
};

export default CategoriesList;
