import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "../api";
import { setLocalStorage, getFromLocalStorage } from "../utils/local-storage";
import Notify from "../utils/Notify";
import { showNotification } from "./notification.store";

const initialState = {
  data: [],
  branches: [],
  loading: false,
  status: "",
  status_completion: {},
  authenticated: false,
};

export const profileEdit = createAsyncThunk("profiles/profile-edit", async (payload, thunkAPI) => {
  try {
    return await api.profileInfo(payload);
  } catch (e) {
    Notify({ status: 421, message: e });

    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const createNextOfKin = createAsyncThunk("profiles/nextOfKin", async (payload, thunkAPI) => {
  try {
    return await api.nextOfKin(payload);
  } catch (e) {
    Notify({ status: 421, message: e });

    return thunkAPI.rejectWithValue(e.response.data);
  }
});

export const uploadProfileDocs = createAsyncThunk(
  "profiles/uploadProfileDocs",
  async (payload, thunkAPI) => {
    try {
      console.log("upload Profile Docs ", payload);

      return await api.uploadDocs(payload);
    } catch (e) {
      Notify({ status: 421, message: e });

      return thunkAPI.rejectWithValue(e.response.data);
    }
  }
);

const profilesSlice = createSlice({
  name: "profiles",
  initialState,
  extraReducers: (builder) => {
    //Notification messages based on responses
    builder.addCase(profileEdit.pending, (state, action) => {
      state.loading = true;
    });
    builder.addCase(profileEdit.fulfilled, (state, action) => {
      console.log("The action payload is ", action);
      state.loading = false;
      state.status = "success";
      state.status_completion=action?.payload?.status_completion
      if (action?.payload?.success === true) {
        // Notify({ status: 200, message: action?.payload?.message });

        setLocalStorage("user", action?.payload?.data);
        setLocalStorage("status_completion", action?.payload?.status_completion);
      } else if (action?.payload?.success === false) {
        Notify({ status: 421, message: action?.payload?.errors });
      }
    });
    builder.addCase(profileEdit.rejected, (state, action) => {
      state.loading = false;
      state.status = "error";
      state.errors = action?.payload?.errors;
      Notify({ status: 421, message: action?.payload?.errors });
    });

    builder.addCase(createNextOfKin.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(createNextOfKin.fulfilled, (state, action) => {
      state.loading = false;
      if (action?.payload?.success === true) {
        state.status_completion=action?.payload?.status_completion
        Notify({ status: 200, message: action?.payload?.message });
        setLocalStorage("user", action?.payload?.data);
        setLocalStorage("status_completion", action?.payload?.status_completion);
      } else if (action?.payload?.success === false) {
        Notify({ status: 421, message: action?.payload?.message });
      }
    });
    builder.addCase(createNextOfKin.rejected, (state, action) => {
      state.loading = false;
      state.errors = action?.payload?.errors;

      Notify({ status: 421, message: action?.payload?.errors });
    });

    builder.addCase(uploadProfileDocs.pending, (state, action) => {
      state.loading = true;
    });

    builder.addCase(uploadProfileDocs.fulfilled, (state, action) => {
      state.loading = false;

      if (action?.payload?.success === false) {
        state.status_completion=action?.payload?.status_completion
        Notify({ status: 421, message: action?.payload?.message });
        setLocalStorage("user", action?.payload?.data);
        setLocalStorage("status_completion", action?.payload?.status_completion);
      } else if (action?.payload?.success === true) {
        Notify({ status: 200, message: action?.payload?.message });
      }
    });

    builder.addCase(uploadProfileDocs.rejected, (state, action) => {
      state.loading = false;
      state.errors = action?.payload?.errors;
      Notify({ status: 421, message: action?.payload?.message });
    });
  },
});

export default profilesSlice.reducer;
