import React from "react";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import Footer from "./Footer";
import LoanItems from "./services/serviceItem";

function Services() {
  const navigate = useNavigate();
  return (
    <div style={{ backgroundColor: "#fff" }}>
      <div className="item item-6">
        <Header />
        <div className="img-fill">
          <section className="" id="">
            <div className="container products-container">
              <div className="row services-container">
                <div className="col-md-12">
                  <div className="section-heading service_heading text-dark">
                    <h2
                      className="text-dark mb-3 my-2"
                      style={{ fontSize: "1.2cm", letterSpacing: "-0.03em", font: "trueno" }}
                    >
                      <strong>OUR PRODUCTS</strong>
                    </h2>
                    <h3 className="my-4">THESE ARE OUR PRODUCTS AND SERVICES</h3>
                  </div>
                </div>

                <LoanItems />
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* <Footer /> */}
    </div>
  );
}

export default Services;
